import React, { useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Autocomplete,
} from '@mui/material';
import MaterialTable, { Action } from '@material-table/core';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type {
  CreateTemplateScheduleDto,
  FilterableColumnInfo,
  FilterGroup,
  ScheduleRun,
  ScheduleType,
  TemplateSchedule,
  TimeRange,
  TimeRangeType,
} from '@interfaces';
import FilterCreation from './FilterCreation';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  useGetSchedulesByTemplateQuery,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteScheduleMutation,
  useUpdateScheduleStatusMutation,
  useGetScheduleRunsQuery,
} from '@apis';
import { setSelectedSchedule } from '@slices';
import { Icons } from '@components';

interface Props {
  templateId: number;
  availableColumns: FilterableColumnInfo[];
  availableEmails: string[];
}

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const TemplateSchedules: React.FC<Props> = ({ templateId, availableColumns, availableEmails }) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();

  const selectedSchedule = useAppSelector((state) => state.report.schedules.selectedSchedule);
  const schedules = useAppSelector((state) => state.report.schedules.list[templateId] || []);

  const [scheduleDialog, setScheduleDialog] = useState(false);
  const [historyDialog, setHistoryDialog] = useState(false);

  const [scheduleName, setScheduleName] = useState('');
  const [scheduleType, setScheduleType] = useState<ScheduleType>('DAILY');
  const [timeOfDay, setTimeOfDay] = useState<Date>(new Date());
  const [daysOfWeek, setDaysOfWeek] = useState<string[]>([]);
  const [dayOfMonth, setDayOfMonth] = useState(1);
  const [emails, setEmails] = useState<string[]>([]);
  const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([]);
  const [logicalOperators, setLogicalOperators] = useState<('AND' | 'OR')[]>([]);

  const { refetch: refetchSchedules } = useGetSchedulesByTemplateQuery(templateId);
  const [createSchedule] = useCreateScheduleMutation();
  const [updateSchedule] = useUpdateScheduleMutation();
  const [deleteSchedule] = useDeleteScheduleMutation();
  const [updateScheduleStatus] = useUpdateScheduleStatusMutation();

  const { data: scheduleHistory = [] } = useGetScheduleRunsQuery({ scheduleId: selectedSchedule?.id ?? 0 }, { skip: !selectedSchedule || !historyDialog });

  const [timeRange, setTimeRange] = useState<TimeRange | undefined>();
  const timeSpanToDate = (timeSpan: string): Date => {
    const [hours] = timeSpan.split(':');
    const date = new Date();
    date.setHours(parseInt(hours));
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  };

  const resetForm = () => {
    setScheduleName('');
    setScheduleType('DAILY');
    setTimeOfDay(new Date());
    setDaysOfWeek([]);
    setDayOfMonth(1);
    setEmails([]);
    setFilterGroups([]);
    setLogicalOperators([]);
    setTimeRange(undefined);
  };

  const handleAdd = () => {
    dispatch(setSelectedSchedule(null));
    resetForm();
    setScheduleDialog(true);
  };

  const handleEdit = (schedule: TemplateSchedule) => {
    dispatch(setSelectedSchedule(schedule));
    setScheduleName(schedule.name);
    setScheduleType(schedule.scheduleType);
    setTimeOfDay(timeSpanToDate(schedule.timeOfDay));
    setDaysOfWeek(schedule.daysOfWeek || []);
    setDayOfMonth(schedule.dayOfMonth || 1);
    setEmails(schedule.emailRecipients);
    setFilterGroups(schedule.filters.groups);
    setLogicalOperators(schedule.filters.interGroupOperators);
    setScheduleDialog(true);
  };

  const handleSave = async () => {
    const scheduleData: CreateTemplateScheduleDto = {
      templateId,
      name: scheduleName,
      scheduleType,
      timeOfDay: formatTimeToTimeSpan(timeOfDay),
      daysOfWeek: scheduleType === 'WEEKLY' ? daysOfWeek : [],
      dayOfMonth: scheduleType === 'MONTHLY' ? dayOfMonth : undefined,
      emailRecipients: emails,
      filters: {
        groups: filterGroups,
        interGroupOperators: logicalOperators,
      },
      timeRange: timeRange,
    };

    try {
      if (selectedSchedule) {
        await updateSchedule({ id: selectedSchedule.id, schedule: { ...scheduleData } }).unwrap();
      } else {
        await createSchedule(scheduleData).unwrap();
      }
      await refetchSchedules();
      setScheduleDialog(false);
    } catch (error) {
      console.error('Failed to save schedule:', error);
    }
  };

  const handleToggleActive = async (schedule: TemplateSchedule) => {
    try {
      await updateScheduleStatus({ id: schedule.id, isActive: !schedule.isActive }).unwrap();
      await refetchSchedules();
    } catch (error) {
      console.error('Failed to toggle schedule status:', error);
    }
  };

  const handleDelete = async (schedule: TemplateSchedule) => {
    try {
      await deleteSchedule(schedule.id).unwrap();
      await refetchSchedules();
    } catch (error) {
      console.error('Failed to delete schedule:', error);
    }
  };

  const handleViewHistory = (schedule: TemplateSchedule) => {
    dispatch(setSelectedSchedule(schedule));
    setHistoryDialog(true);
  };

  const formatTimeToTimeSpan = (date: Date): string => {
    return `${date.getHours()}:00:00`;
  };

  const renderTimeRangeSection = () => (
    <Box className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <Box>
        <Typography variant="subtitle2" className="!mb-3">
          Time Range
        </Typography>
        <FormControl fullWidth size="small">
          <InputLabel>Range Type</InputLabel>
          <Select
            value={timeRange?.type || ''}
            onChange={(e) => {
              const type = e.target.value as TimeRangeType;
              if (type) {
                setTimeRange({
                  type,
                  value: type === 'all' ? undefined : 1,
                });
              } else {
                setTimeRange(undefined);
              }
            }}
            label="Range Type">
            <MenuItem value="">
              <em>Use last run time</em>
            </MenuItem>
            <MenuItem value="all">All Time (No Date Filter)</MenuItem>
            <MenuItem value="day">Last N Days</MenuItem>
            <MenuItem value="week">Last N Weeks</MenuItem>
            <MenuItem value="month">Last N Months</MenuItem>
            <MenuItem value="year">Last N Years</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {timeRange && timeRange.type !== 'all' && (
        <Box>
          <Typography variant="subtitle2" className="!mb-3">
            Range Value
          </Typography>
          <TextField
            type="number"
            label={`Number of ${timeRange.type}s`}
            value={timeRange.value || 1}
            onChange={(e) =>
              setTimeRange((prev) => ({
                ...prev!,
                value: parseInt(e.target.value),
              }))
            }
            inputProps={{ min: 1 }}
            fullWidth
            size="small"
          />
        </Box>
      )}
    </Box>
  );

  const tableActions: Action<TemplateSchedule>[] = [
    {
      icon: Icons.Add,
      tooltip: 'Add Schedule',
      isFreeAction: true,
      onClick: () => handleAdd(),
    },
    {
      icon: Icons.Edit,
      tooltip: 'Edit Schedule',
      onClick: (_event, rowData) => {
        if (rowData && !Array.isArray(rowData)) {
          handleEdit(rowData);
        }
      },
    },
    {
      icon: Icons.History,
      tooltip: 'View History',
      onClick: (_event, rowData) => {
        if (rowData && !Array.isArray(rowData)) {
          handleViewHistory(rowData);
        }
      },
    },
    {
      icon: Icons.DeleteForeverIcon,
      tooltip: 'Delete Schedule',
      onClick: (_event, rowData) => {
        if (rowData && !Array.isArray(rowData)) {
          handleDelete(rowData);
        }
      },
    },
  ];

  return (
    <>
      <MaterialTable
        title="Schedules"
        columns={[
          { title: 'Name', field: 'name' },
          {
            title: 'Schedule',
            render: (rowData: TemplateSchedule) => {
              console.warn(rowData);
              if (!rowData) return '-';

              const schedule = [];
              if (rowData.scheduleType === 'DAILY') schedule.push('Daily');
              else if (rowData.scheduleType === 'WEEKLY') schedule.push(`Weekly on ${rowData.daysOfWeek?.join(', ')}`);
              else if (rowData.scheduleType === 'MONTHLY') schedule.push(`Monthly on day ${rowData.dayOfMonth}`);

              const timeOfDay = rowData.timeOfDay?.split(':')[0];
              schedule.push(`at ${timeOfDay}:00`);

              return schedule.join(' ');
            },
          },
          {
            title: 'Recipients',
            render: (rowData: TemplateSchedule) => (
              <Box className="flex flex-wrap gap-1">
                {rowData.emailRecipients.map((email) => (
                  <Chip key={email} label={email} size="small" />
                ))}
              </Box>
            ),
          },
          {
            title: 'Last Run',
            render: (rowData: TemplateSchedule) => (rowData.lastRun ? new Date(rowData.lastRun).toLocaleString() : 'Never'),
          },
          {
            title: 'Active',
            render: (rowData: TemplateSchedule) => <Switch checked={rowData.isActive} onChange={() => handleToggleActive(rowData)} size="small" />,
          },
        ]}
        data={schedules || []}
        actions={tableActions}
        options={{
          actionsColumnIndex: -1,
          pageSize: 5,
          pageSizeOptions: [5, 10, 20],
        }}
      />

      {/* Schedule Dialog */}
      <Dialog open={scheduleDialog} onClose={() => setScheduleDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>{selectedSchedule ? 'Edit Schedule' : 'Create Schedule'}</DialogTitle>
        <DialogContent>
          <Box className="space-y-4 pt-2">
            <TextField label="Schedule Name" value={scheduleName} onChange={(e) => setScheduleName(e.target.value)} fullWidth />

            <FormControl fullWidth>
              <InputLabel>Schedule Type</InputLabel>
              <Select value={scheduleType} onChange={(e) => setScheduleType(e.target.value as ScheduleType)} label="Schedule Type">
                <MenuItem value="DAILY">Daily</MenuItem>
                <MenuItem value="WEEKLY">Weekly</MenuItem>
                <MenuItem value="MONTHLY">Monthly</MenuItem>
              </Select>
            </FormControl>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                label="Hour of Day"
                value={timeOfDay}
                onChange={(newValue) => newValue && setTimeOfDay(newValue)}
                views={['hours']}
                format="HH:00"
                ampm={false}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    helperText: '24-hour format',
                  },
                }}
              />
            </LocalizationProvider>

            {scheduleType === 'WEEKLY' && (
              <Box>
                <Typography variant="subtitle2" className="mb-2">
                  Select Days
                </Typography>
                <Box className="flex flex-wrap gap-2">
                  {DAYS_OF_WEEK.map((day) => (
                    <Chip
                      key={day}
                      label={day}
                      onClick={() => {
                        if (daysOfWeek.includes(day)) {
                          setDaysOfWeek(daysOfWeek.filter((d) => d !== day));
                        } else {
                          setDaysOfWeek([...daysOfWeek, day]);
                        }
                      }}
                      color={daysOfWeek.includes(day) ? 'primary' : 'default'}
                      className="cursor-pointer"
                    />
                  ))}
                </Box>
              </Box>
            )}

            {scheduleType === 'MONTHLY' && (
              <TextField
                label="Day of Month"
                type="number"
                value={dayOfMonth}
                onChange={(e) => setDayOfMonth(parseInt(e.target.value))}
                inputProps={{ min: 1, max: 31 }}
                fullWidth
              />
            )}

            <Box>
              <Box className="flex gap-2">
                <TextField
                  label="Add Email"
                  placeholder="Enter email and press Enter"
                  fullWidth
                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test((e.target as HTMLInputElement).value)) {
                      setEmails([...emails, (e.target as HTMLInputElement).value]);
                      (e.target as HTMLInputElement).value = '';
                    }
                  }}
                />
              </Box>
              <Box className="flex flex-wrap gap-2 mt-2">
                {emails.map((email) => (
                  <Chip key={email} label={email} onDelete={() => setEmails(emails.filter((e) => e !== email))} />
                ))}
              </Box>
            </Box>
            {renderTimeRangeSection()}
            <Box>
              <Typography variant="subtitle2" className="mb-2">
                Filters
              </Typography>
              <Typography variant="caption" color="textSecondary" className="block mb-2">
                Note: A date range filter will automatically be added to fetch data between runs if applicable
              </Typography>
              <FilterCreation
                filterGroups={filterGroups}
                setFilterGroups={setFilterGroups}
                interGroupOperators={logicalOperators}
                setInterGroupOperators={setLogicalOperators}
                availableColumns={availableColumns}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setScheduleDialog(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={historyDialog} onClose={() => setHistoryDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Schedule History</DialogTitle>
        <DialogContent>
          <MaterialTable
            columns={[
              {
                title: 'Run Time',
                render: (rowData: ScheduleRun) => new Date(rowData.startTime).toLocaleString(),
              },
              {
                title: 'Status',
                render: (rowData: ScheduleRun) => <Chip label={rowData.status} color={rowData.status === 'SUCCESS' ? 'success' : 'error'} size="small" />,
              },
              { title: 'Records', field: 'recordCount' },
              { title: 'Email Sent', field: 'emailSent', type: 'boolean' },
              {
                title: 'Duration',
                render: (rowData: ScheduleRun) => {
                  const start = new Date(rowData.startTime);
                  const end = rowData.endTime ? new Date(rowData.endTime) : new Date();
                  const diff = Math.round((end.getTime() - start.getTime()) / 1000);
                  return `${diff} seconds`;
                },
              },
            ]}
            data={scheduleHistory}
            options={{
              search: false,
              paging: true,
              pageSize: 5,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHistoryDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplateSchedules;
