import { useGetTpdTreeQuery } from '@apis';
import { useAppSelector, useForm } from '@hooks';
import { MediaConfigurationInformation, PropertyInfo, PostMediaConfigurationDto } from '@interfaces';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Switch,
  FormControlLabel,
  CircularProgress,
  Grid,
  Paper,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TpdBindingField from './TpdBindingField';
import { v4 as uuidv4 } from 'uuid';

interface AddMediaConfigurationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (config: PostMediaConfigurationDto) => void;
  mediaTypes: MediaConfigurationInformation[];
  isLoading?: boolean;
}

const AddMediaConfigurationDialog = ({ isOpen, onClose, onSave, mediaTypes, isLoading }: AddMediaConfigurationDialogProps) => {
  const { t } = useTranslation('pano');
  const [selectedType, setSelectedType] = useState<MediaConfigurationInformation | null>(null);
  const [selectedTypeId, setSelectedTypeId] = useState<string>('');
  const { activeTeam } = useAppSelector((x) => x.app);
  const { tpdTree } = useAppSelector((x) => x.mahon);

  useGetTpdTreeQuery(activeTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
    skip: !activeTeam,
  });

  const handleMediaSubmit = (): void => {
    console.warn(formData);
    if (selectedType) {
      onSave({
        mediaConfigurationDto: {
          ...formData,
          typeName: mediaTypes.find((x) => x.editorMetadata.type === selectedTypeId)?.name,
          visualId: formData.visualId ?? uuidv4(),
          thirdPartyDataSourceNotification: {
            bindingPath: formData.thirdPartyDataSourceBindingPath ?? null,
            isActive: formData.thirdPartyDataSourceBindingPath ? true : false,
            updateSettings: formData.updateMode
              ? {
                  textOptions: formData.updateMode,
                }
              : null,
          },
        },
      });
    }
  };

  const { handleSubmit, handleChange, formData, errors, setFormData, resetForm } = useForm<any>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      description: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleMediaSubmit,
    initialValues: {},
  });

  useEffect(() => {
    if (!isOpen) {
      setSelectedType(null);
      setSelectedTypeId('');
      setFormData({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedType) {
      const defaultValues = {
        ...Object.entries(selectedType.editorMetadata.baseProperties).reduce(
          (acc, [key, field]) => ({
            ...acc,
            [key]: field.defaultValue,
          }),
          {},
        ),
        ...Object.entries(selectedType.editorMetadata.specificProperties).reduce(
          (acc, [key, field]) => ({
            ...acc,
            [key]: field.defaultValue,
            ...(field.type === 'tpdBinding' && {
              thirdPartyDataSourceBindingPath: '',
              updateMode: field.hasUpdateSettings ? 'replace' : undefined,
            }),
          }),
          {},
        ),
      };
      setFormData(defaultValues);
    }
  }, [selectedType]);

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const typeId = event.target.value as string;
    setSelectedTypeId(typeId);
    const type = mediaTypes.find((t) => t.editorMetadata.type === typeId);
    setSelectedType(type || null);
  };

  const handleClose = () => {
    onClose();
    resetForm();
  };

  const renderField = (key: string, field: PropertyInfo) => {
    switch (field.type) {
      case 'string':
        return (
          <TextField
            name={key}
            label={field.label}
            fullWidth
            value={formData[key] ?? field.defaultValue ?? ''}
            onChange={handleChange}
            required={field.required}
            error={!!errors?.[key]}
            helperText={errors?.[key]}
          />
        );

      case 'boolean':
        return (
          <FormControlLabel
            control={<Switch name={key} checked={formData[key] ?? field.defaultValue ?? false} onChange={handleChange} />}
            label={field.label}
          />
        );

      case 'number':
        return (
          <TextField
            name={key}
            label={field.label}
            type="number"
            fullWidth
            value={formData[key] ?? field.defaultValue ?? ''}
            onChange={handleChange}
            required={field.required}
            error={!!errors?.[key]}
            helperText={errors?.[key]}
          />
        );

      case 'select':
        return (
          <TextField
            variant="outlined"
            name={key}
            placeholder={field.label}
            label={field.label}
            fullWidth
            select
            onChange={handleChange}
            value={formData[key] ?? field.defaultValue ?? ''}
            error={!!errors?.[key]}
            required={field.required}
            helperText={errors?.[key]}>
            {field.options?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        );

      case 'tpdBinding':
        return (
          <TpdBindingField
            bindingValue={formData.thirdPartyDataSourceBindingPath || ''}
            updateMode={formData.updateMode}
            hasUpdateSettings={field.hasUpdateSettings}
            onChange={(name, value) => handleChange({ target: { name, value } })}
            tpdTree={tpdTree}
          />
        );
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="md">
      <DialogTitle>{t('addNewMediaConfig')}</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12} className="!my-2">
            <TextField
              variant="outlined"
              name="type"
              label={t('mediaType')}
              placeholder={t('mediaType') as string}
              fullWidth
              select
              onChange={handleTypeChange}
              value={selectedTypeId}>
              {mediaTypes.map((type) => (
                <MenuItem key={type.editorMetadata.type} value={type.editorMetadata.type}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {selectedType && (
            <Grid container item xs={12}>
              <form className="w-full" id="mediaConfigForm" onSubmit={handleSubmit}>
                <Paper className="space-y-4 p-4 w-full">
                  {Object.entries(selectedType.editorMetadata.baseProperties).map(([key, field]) => (
                    <Grid item xs={12} key={key}>
                      {renderField(key, field)}
                    </Grid>
                  ))}

                  {Object.entries(selectedType.editorMetadata.specificProperties).map(([key, field]) => (
                    <Grid item xs={12} key={key}>
                      {renderField(key, field)}
                    </Grid>
                  ))}
                </Paper>
              </form>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="secondary" disabled={isLoading}>
          {t('cancelButtonText')}
        </Button>
        {selectedType && (
          <Button type="submit" variant="contained" form="mediaConfigForm" color="primary" disabled={isLoading}>
            {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AddMediaConfigurationDialog;
