import { usePostRoleMutation } from '@apis';
import { faArrowDownAZ, faArrowUpAZ, faBan, faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';
import { NewRoleForm, PermissionTableItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { blue, green, red } from '@constants';
import { openSnackbar } from '@slices';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  refetch?: () => void;
};

const formInitialValues = {
  name: '',
  active: true,
  isSysAdmin: false,
  serviceId: null,
  permissionIds: [],
} as NewRoleForm;

const AddRoleDialogForm = ({ setOpen, open, refetch }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { services } = useAppSelector((x) => x.service);
  const { activeTeam, userRights } = useAppSelector((x) => x.app);
  const { roles } = useAppSelector((x) => x.role);
  const { publicPermissions, allPermissions } = useAppSelector((x) => x.permission);
  const [tableData, setTableData] = useState<PermissionTableItem[]>([]);
  const dispatch = useAppDispatch();
  const [sortState, setSortState] = useState<'none' | 'asc' | 'desc'>('none');
  const handleCloseDialog = (_e?: {}, reason?: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason === 'backdropClick') return;
    resetForm({ ...formInitialValues });
    setTableData([]);
    setOpen(false);
  };

  const [addNewRole, { isLoading }] = usePostRoleMutation();
  const handleRoleSubmit = (): void => {
    addNewRole(formData)
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        if (refetch) refetch();
        handleCloseDialog();
      })
      .catch((err) => {
        console.debug('Failed while attempting to create a new role', err);
        return;
      });
  };

  const nameCheck = (value: string): boolean => {
    return !roles.some((x) => x.name.toLowerCase().trim() === value.toLocaleLowerCase().trim());
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, resetForm } = useForm<NewRoleForm>({
    validations: {
      name: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
        custom: {
          isValid: nameCheck,
          message: t('nameAlreadyExists'),
        },
      },
      serviceId: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleRoleSubmit,
    initialValues: { ...formInitialValues },
  });

  useEffect(() => {
    let permissionTableData = [] as PermissionTableItem[];
    userRights.isSysAdmin
      ? allPermissions.forEach((permission) => {
          permissionTableData.push({
            id: permission.id,
            name: permission.name,
            public: permission.public,
            adminOnly: permission.adminOnly,
            active: permission.active,
            serviceId: permission.serviceId,
            tableData: {
              checked: false,
            },
          });
        })
      : publicPermissions.forEach((permission) => {
          permissionTableData.push({
            id: permission.id,
            name: permission.name,
            public: permission.public,
            adminOnly: permission.adminOnly,
            active: permission.active,
            serviceId: permission.serviceId,
            tableData: {
              checked: false,
            },
          });
        });
    setTableData(permissionTableData);
  }, [publicPermissions, allPermissions, open]);

  const handleSysAdminChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean): void => {
    handleChange(e, checked);
    if (!checked) {
      tableData.forEach((rowData, index) => {
        if (rowData.adminOnly) {
          if (rowData.tableData.checked) tableData[index].tableData.checked = false;
        }
      });

      setTableData(tableData);
      setFormData({
        ...formData,
        permissionIds: tableData
          .filter((x) => x.tableData.checked)
          .map((item) => {
            return item.id;
          }),
      });
    }
  };
  const onSelectionChange = (data: PermissionTableItem[], rowData?: PermissionTableItem | undefined): void => {
    let permissionIds = [] as number[];
    let count = tableData.length;
    if (!formData.isSysAdmin) {
      count = tableData.filter((x) => !x.adminOnly).length;
    }
    if (!rowData) {
      let isChecked = false;
      if (tableData.filter((x) => x.tableData.checked).length !== count) isChecked = true;

      tableData.forEach((rowData, index) => {
        if (!(!formData.isSysAdmin && rowData.adminOnly)) {
          tableData[index].tableData.checked = isChecked;
          permissionIds.push(rowData.id);
        }
      });

      setFormData({ ...formData, permissionIds: permissionIds });
      setTableData(tableData);
      return;
    }

    tableData.forEach((rowData, index) => {
      if (data.find((x) => x.id === rowData.id)) {
        permissionIds.push(rowData.id);
        tableData[index].tableData.checked = true;
      } else if (tableRef?.current.dataManager.data.find((x: any) => x.id === rowData.id)) tableData[index].tableData.checked = false;
    });

    setFormData({ ...formData, permissionIds: permissionIds });
    setTableData(tableData);
  };
  return (
    <Dialog open={open} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
      <DialogTitle id="dialog-title">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            {t('addNewRole')}
          </Grid>
          <Grid item>
            <ToggleButtonGroup
              value={sortState}
              exclusive
              onChange={(_event, newSort) => {
                if (newSort !== null) {
                  setSortState(newSort);
                  let sortedData = [...tableData];
                  if (newSort === 'desc') {
                    sortedData.sort((a, b) => (b.tableData.checked ? 1 : 0) - (a.tableData.checked ? 1 : 0));
                  } else if (newSort === 'asc') {
                    sortedData.sort((a, b) => (a.tableData.checked ? 1 : 0) - (b.tableData.checked ? 1 : 0));
                  }
                  setTableData(sortedData);
                }
              }}
              aria-label="sort selected"
              size="small">
              <Tooltip title={t('selectedFirst')}>
                <ToggleButton value="desc" aria-label="selected first">
                  <FontAwesomeIcon
                    icon={faArrowUpAZ}
                    style={{
                      color: sortState === 'desc' ? green[500] : blue[500],
                      height: 16,
                      width: 16,
                    }}
                  />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={t('selectedLast')}>
                <ToggleButton value="asc" aria-label="selected last">
                  <FontAwesomeIcon
                    icon={faArrowDownAZ}
                    style={{
                      color: sortState === 'asc' ? green[500] : blue[500],
                      height: 16,
                      width: 16,
                    }}
                  />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={t('noSort')}>
                <ToggleButton value="none" aria-label="no sort">
                  <FontAwesomeIcon
                    icon={faBan}
                    style={{
                      color: sortState === 'none' ? green[500] : blue[500],
                      height: 16,
                      width: 16,
                    }}
                  />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Paper className="w-full p-5 space-y-4 mb-4">
            <Typography variant="subtitle1" gutterBottom>
              {t('roleDetails')}
            </Typography>

            <TextField
              name="name"
              autoComplete="nope"
              label={t('name')}
              placeholder={t('name') as string}
              fullWidth
              value={formData.name}
              onChange={handleChange}
              variant="outlined"
              {...(errors?.name && { error: true, helperText: errors.name })}
            />

            <FormGroup row>
              <FormControlLabel control={<Checkbox checked={formData.active} onChange={handleChange} color="primary" name="active" />} label={t('active')} />
            </FormGroup>

            {activeTeam?.isSysAdmin && (
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox checked={formData.isSysAdmin} onChange={(e, checked) => handleSysAdminChange(e, checked)} color="primary" name="isSysAdmin" />
                  }
                  label={t('sysAdmin')}
                />
              </FormGroup>
            )}

            <TextField
              select
              name="serviceId"
              label={t('selectService')}
              placeholder={t('selectService') as string}
              fullWidth
              variant="outlined"
              value={formData.serviceId ?? ''}
              onChange={handleChange}
              {...(errors?.serviceId && { error: true, helperText: errors.serviceId })}>
              {services.map((service) => (
                <MenuItem key={service.id} value={service.id}>
                  {t(service.name)}
                </MenuItem>
              ))}
            </TextField>
          </Paper>

          <Paper className="w-full space-y-4">
            <MaterialTable
              tableRef={tableRef}
              options={{
                tableLayout: 'auto',
                addRowPosition: 'first',
                actionsColumnIndex: -1,
                sorting: false,
                search: true,
                selection: true,
                draggable: false,
                selectionProps: (rowData) => ({
                  disabled: !formData.isSysAdmin && rowData.adminOnly,
                }),
              }}
              title={t('permissions')}
              onSelectionChange={onSelectionChange}
              columns={[
                { title: t('name'), field: 'name' },
                {
                  title: t('active'),
                  field: 'active',
                  type: 'boolean',
                  align: 'center',
                  width: '10%',
                  render: (rowData) =>
                    rowData.active ? (
                      <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                    ),
                },
                {
                  title: t('public'),
                  field: 'public',
                  type: 'boolean',
                  align: 'center',
                  width: '10%',
                  render: (rowData) =>
                    rowData.public ? (
                      <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                    ),
                },
                {
                  title: t('adminOnly'),
                  field: 'adminOnly',
                  type: 'boolean',
                  align: 'center',
                  width: '10%',
                  render: (rowData) =>
                    rowData.adminOnly ? (
                      <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
                    ) : (
                      <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
                    ),
                },
              ]}
              data={activeTeam?.isSysAdmin ? tableData : tableData.filter((x) => !x.adminOnly)}
            />
          </Paper>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseDialog} disabled={isLoading}>
            {t('cancelButtonText')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
            {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddRoleDialogForm;
