import React, { useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, FormControlLabel, FormGroup, Grid, Paper, Switch, Typography } from '@mui/material';
import MaterialTable, { Column } from '@material-table/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { TableIcons, WorkflowStepFiltersEditor } from '@components';
import { green, red } from '@constants';
import { WorkflowStepItem, WorkflowStepFilterItemTableRow, WorkflowItem } from '@interfaces';
import { UnsavedChangesContext } from '@hooks';
import _ from 'lodash';

interface TeamWorkflowUIProps {
  t: (key: string) => string;
  workflowFlag: boolean;
  handleWorkFlowFlagChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  tableRef: React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  isGettingWorkFlows: boolean;
  isGettingFilters: boolean;
  isGettingFiltersSuccess: boolean;
  rowCheck: boolean;
  tableData: WorkflowStepItem[];
  tableFiltersData: WorkflowStepFilterItemTableRow[];
  setTableFiltersData: React.Dispatch<React.SetStateAction<WorkflowStepFilterItemTableRow[]>>;
  teamId: string;
  workflowId: string;
  workFlows: WorkflowItem[];
  isUpdatingFilters: boolean;
  onReset: () => void;
  onSubmit: () => void;
}

const TeamWorkflowStepFilters: React.FC<TeamWorkflowUIProps> = ({
  t,
  workflowFlag,
  handleWorkFlowFlagChange,
  tableRef,
  isGettingWorkFlows,
  isGettingFilters,
  isGettingFiltersSuccess,
  rowCheck,
  tableData,
  tableFiltersData,
  setTableFiltersData,
  teamId,
  workflowId,
  workFlows,
  isUpdatingFilters,
  onReset,
  onSubmit,
}) => {
  const unsavedChangesContext = useContext(UnsavedChangesContext);
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);

  useEffect(() => {
    if (unsavedChangesContext) {
      unsavedChangesContext.setIsDirty(hasFormChanged);
    }
  }, [hasFormChanged]);

  const resetForm = () => {
    onReset();
    setHasFormChanged(false);
  };

  const handleSubmit = () => {
    onSubmit();
    setHasFormChanged(false);
  };

  const columns: Array<Column<WorkflowStepItem>> = [
    { title: t('stepIndex'), field: 'stepIndex' },
    { title: t('name'), field: 'name' },
    {
      title: t('authorOnly'),
      field: 'authorOnly',
      type: 'boolean',
      render: (rowData) =>
        rowData.authorOnly ? <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />,
    },
    {
      title: t('isService'),
      field: 'isService',
      type: 'boolean',
      render: (rowData) =>
        rowData.isService ? <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} /> : <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />,
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} className="pb-5">
        <Typography variant="h6" gutterBottom>
          {t('teamWorkflowPageTitle')}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {t('teamWorkflowPageSubtitle')}
        </Typography>
      </Grid>
      <Paper className="p-5 w-full space-y-6">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch className="float-right" checked={workflowFlag} onChange={handleWorkFlowFlagChange} color="primary" name="allowStepFilterOverride" />
              }
              label={t('allowStepFilterOverrideLabel')}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <MaterialTable
            icons={TableIcons}
            tableRef={tableRef}
            isLoading={isGettingWorkFlows || isGettingFilters}
            options={{
              tableLayout: 'auto',
              addRowPosition: 'first',
              actionsColumnIndex: -1,
              rowStyle: (rowData) => ({
                border:
                  isGettingFiltersSuccess && rowCheck && !rowData.isService && tableFiltersData.filter((x) => x.workflowStepId === rowData.id).length === 0
                    ? `2px solid ${red[500]}`
                    : '',
              }),
            }}
            columns={columns}
            data={tableData}
            title={t('worflowSteps')}
            detailPanel={(rowData) => {
              return rowData.rowData.isService ? (
                <></>
              ) : (
                <Paper className="p-5 w-full">
                  <WorkflowStepFiltersEditor
                    worflowStepId={rowData.rowData.id}
                    teamId={parseInt(teamId)}
                    workflowId={workFlows.find((x) => x.id === parseInt(workflowId))?.workflowId ?? 0}
                    tableData={tableFiltersData}
                    setTableData={(newData) => {
                      console.warn(newData, tableFiltersData);
                      const isChanged = !_.isEqual(tableFiltersData, newData);
                      setTableFiltersData(newData);
                      if (isChanged) {
                        setHasFormChanged(true);
                      }
                    }}
                  />
                </Paper>
              );
            }}
            onRowClick={(_event, _rowData, togglePanel) => {
              if (togglePanel) return togglePanel.toggleDetailPanel();
            }}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end">
          <Button variant="contained" color="secondary" className="!mr-5" disabled={isUpdatingFilters} onClick={resetForm}>
            {isUpdatingFilters && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('cancelButtonText')}
          </Button>
          <Button variant="contained" color="primary" disabled={isUpdatingFilters} onClick={handleSubmit}>
            {isUpdatingFilters && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default TeamWorkflowStepFilters;
