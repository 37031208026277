import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryHandler } from './base.api';
import { sysConfig } from '@utils';
import {
  TableInfo,
  ReportRequestDto,
  Template,
  ReportExecutionRequest,
  ReportResult,
  ScheduleRun,
  TemplateSchedule,
  CreateTemplateScheduleDto,
} from '@interfaces';

export const reportApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'reportApi',
  endpoints: (builder) => ({
    getDatabaseSchema: builder.query<TableInfo[], void>({
      query: () => ({
        url: `${sysConfig.horreumUri}/api/v1/Schema`,
        method: 'GET',
      }),
    }),
    generateReport: builder.mutation<ReportResult, ReportRequestDto>({
      query: (payload) => ({
        url: `${sysConfig.horreumUri}/api/v1/Report`,
        method: 'POST',
        body: payload,
      }),
    }),
    getTemplates: builder.query<Template[], void>({
      query: () => ({
        url: `${sysConfig.horreumUri}/api/v1/template`,
        method: 'GET',
      }),
    }),
    createTemplate: builder.mutation<Template, Omit<Template, 'id'>>({
      query: (template) => ({
        url: `${sysConfig.horreumUri}/api/v1/template`,
        method: 'POST',
        body: template,
      }),
    }),
    updateTemplate: builder.mutation<Template, Template>({
      query: (template) => ({
        url: `${sysConfig.horreumUri}/api/v1/template/${template.id}`,
        method: 'PUT',
        body: template,
      }),
    }),
    runTemplate: builder.mutation<ReportResult, ReportExecutionRequest>({
      query: (payload) => ({
        url: `${sysConfig.horreumUri}/api/v1/template/${payload.templateId}/run`,
        method: 'POST',
        body: payload.filterInfo,
      }),
    }),
    getSchedulesByTemplate: builder.query<TemplateSchedule[], number>({
      query: (templateId) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule/template/${templateId}`,
        method: 'GET',
      }),
    }),

    getSchedule: builder.query<TemplateSchedule, number>({
      query: (id) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule/${id}`,
        method: 'GET',
      }),
    }),

    createSchedule: builder.mutation<number, CreateTemplateScheduleDto>({
      query: (schedule) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule`,
        method: 'POST',
        body: schedule,
      }),
    }),

    updateSchedule: builder.mutation<void, { id: number; schedule: CreateTemplateScheduleDto }>({
      query: ({ id, schedule }) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule/${id}`,
        method: 'PUT',
        body: schedule,
      }),
    }),

    deleteSchedule: builder.mutation<void, number>({
      query: (id) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule/${id}`,
        method: 'DELETE',
      }),
    }),

    updateScheduleStatus: builder.mutation<TemplateSchedule, { id: number; isActive: boolean }>({
      query: ({ id, isActive }) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule/${id}/status`,
        method: 'PUT',
        body: isActive,
      }),
    }),

    getScheduleRuns: builder.query<
      ScheduleRun[],
      {
        scheduleId: number;
        startFrom?: string;
        endTo?: string;
        status?: string;
      }
    >({
      query: ({ scheduleId, startFrom, endTo, status }) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule/${scheduleId}/runs`,
        method: 'GET',
        params: { startFrom, endTo, status },
      }),
    }),

    getLatestRun: builder.query<ScheduleRun, number>({
      query: (scheduleId) => ({
        url: `${sysConfig.horreumUri}/api/v1/schedule/${scheduleId}/runs/latest`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDatabaseSchemaQuery,
  useGenerateReportMutation,
  useGetTemplatesQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useRunTemplateMutation,
  useGetSchedulesByTemplateQuery,
  useGetScheduleQuery,
  useCreateScheduleMutation,
  useUpdateScheduleMutation,
  useDeleteScheduleMutation,
  useUpdateScheduleStatusMutation,
  useGetScheduleRunsQuery,
  useGetLatestRunQuery,
} = reportApi;
