import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryHandler } from './base.api';
import { SearchType } from '@constants';
import {
  MemberItem,
  TeamAddForm,
  TeamDetailedItem,
  TeamEventItem,
  TeamForm,
  TeamMemberForm,
  TeamMemberItem,
  TeamNameDto,
  TeamResults,
  TeamRoleItem,
  TeamServiceItem,
} from '@interfaces';

export const teamApi = createApi({
  baseQuery: baseQueryHandler,
  reducerPath: 'teamApi',
  endpoints: (builder) => ({
    getTeam: builder.query<TeamDetailedItem, number>({
      query: (payload) => ({
        url: `/api/v2/team`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}` },
      }),
    }),
    getTeamNames: builder.query<TeamNameDto[], string>({
      query: (payload) => ({
        url: `/api/v3/team/names?teamIds=${payload}`,
        method: 'GET',
      }),
    }),
    getTeams: builder.query<
      TeamResults,
      { pageNo: number; pageSize: number; searchTerm: string; parentTeamId: number; teamTypeDisplayName?: string; searchType?: string }
    >({
      query: (payload) => ({
        url: `/api/v2/team/search?searchTerm=${payload.searchTerm}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&teamTypeDisplayName=${
          payload.teamTypeDisplayName ?? ''
        }&searchType=${payload.searchType ?? SearchType.OWNED_TEAMS}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.parentTeamId}` },
      }),
    }),
    getTeamsSearch: builder.query<
      TeamResults,
      { pageNo: number; pageSize: number; searchTerm: string; parentTeamId: number; teamTypeDisplayName?: string; searchType?: string }
    >({
      query: (payload) => ({
        url: `/api/v2/team/search?searchTerm=${payload.searchTerm}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&teamTypeDisplayName=${
          payload.teamTypeDisplayName ?? ''
        }&searchType=${payload.searchType ?? SearchType.OWNED_TEAMS}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.parentTeamId}` },
      }),
    }),
    getOwnerTeams: builder.query<
      TeamResults,
      { pageNo: number; pageSize: number; searchTerm: string; parentTeamId: number; teamTypeDisplayName?: string; searchType?: string }
    >({
      query: (payload) => ({
        url: `/api/v2/team/ownerTeamSearch?searchTerm=${payload.searchTerm}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}&teamTypeDisplayName=${
          payload.teamTypeDisplayName ?? ''
        }&searchType=${payload.searchType ?? SearchType.OWNED_TEAMS}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.parentTeamId}` },
      }),
    }),
    getTeamServices: builder.query<TeamServiceItem[], number>({
      query: (payload) => ({
        url: `/api/v2/team/services/admin`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}`, 'X-LEXACOM-TIMEZONE': '01:00:00' },
      }),
    }),
    getTeamRoles: builder.query<TeamRoleItem[], { teamId: number; searchTerm?: string }>({
      query: (payload) => ({
        url: payload.searchTerm ? `/api/v2/team/roles?searchTerm=${payload.searchTerm}` : '/api/v2/team/roles',
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    getTeamMembers: builder.query<TeamMemberItem[], number>({
      query: (payload) => ({
        url: `/api/v2/team/members`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload}` },
      }),
    }),
    getTeamMembersSearch: builder.query<TeamMemberItem[], { teamId: number; searchTerm: string }>({
      query: (payload) => ({
        url: `/api/v2/team/membersSearch?searchTerm=${payload.searchTerm}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    getTeamEvents: builder.query<TeamEventItem[], { teamId: number; start: Date; end: Date }>({
      query: (payload) => ({
        url: `/api/v3/team/events?start=${payload.start.toISOString().substring(0, 10)}&end=${payload.end.toISOString().substring(0, 10)}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    getOwnerData: builder.query<string[], { teamId: number; searchTerm: string }>({
      query: (payload) => ({
        url: `/api/v2/team/${payload.searchTerm}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    putTeam: builder.mutation<void, { teamId: number; newData: TeamForm; isDeleted?: boolean }>({
      query: (payload) => ({
        url: `/api/v2/team`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: { ...payload.newData, isDeleted: payload.isDeleted ?? false },
      }),
    }),
    deactivateTeam: builder.mutation<void, { teamId: number; reason: string }>({
      query: (payload) => ({
        url: `/api/v2/team/deactivate/?reason=${payload.reason}`,
        method: 'POST',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    postTeamMember: builder.mutation<void, { teamId: number; newMember: TeamMemberForm }>({
      query: (payload) => ({
        url: `/api/v2/team/invite/user`,
        method: 'POST',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: payload.newMember,
      }),
    }),
    postTeam: builder.mutation<number, { teamId: number; newTeam: TeamAddForm }>({
      query: (payload) => ({
        url: `/api/v2/team`,
        method: 'POST',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: payload.newTeam,
      }),
    }),
    postTeamMembers: builder.mutation<TeamMemberItem[], { teamId: number; importData: any[] }>({
      query: (payload) => ({
        url: `/api/v2/team/members`,
        method: 'POST',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: payload.importData,
      }),
    }),
    sendMembershipInvite: builder.mutation<void, { teamId: number; userId: number }>({
      query: (payload) => ({
        url: `/api/v2/team/invite/user/${payload.userId}`,
        method: 'POST',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    validateTeamMember: builder.mutation<MemberItem, { teamId: number; email: string }>({
      query: (payload) => ({
        url: `/api/v2/team/validate/${payload.email}`,
        method: 'GET',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    grantService: builder.mutation<void, { teamId: number; data: any }>({
      query: (payload) => ({
        url: `/api/v3/team/service`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: JSON.stringify(payload.data),
      }),
    }),
    putMembershipInvitationRequired: builder.mutation<void, { teamId: number; membershipInvitationRequired: boolean }>({
      query: (payload) => ({
        url: `/api/v2/team/members/invitation/required`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: JSON.stringify(payload.membershipInvitationRequired),
      }),
    }),
    updateTeamParent: builder.mutation<void, { teamId: number; newParentId: number }>({
      query: (payload) => ({
        url: `/api/v3/team/parent/${payload.newParentId}`,
        method: 'PUT',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    revokeService: builder.mutation<void, { teamId: number; serviceIds: number[]; reason: string }>({
      query: (payload) => ({
        url: `/api/v2/team/service/?reason=${payload.reason}`,
        method: 'DELETE',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: payload.serviceIds,
      }),
    }),
    revokeMembership: builder.mutation<void, { teamId: number; userId: number; reason: string }>({
      query: (payload) => ({
        url: `/api/v2/team/member/${payload.userId}/?reason=${payload.reason}`,
        method: 'DELETE',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
      }),
    }),
    revokeMembers: builder.mutation<void, { teamId: number; userIds: number[] }>({
      query: (payload) => ({
        url: `/api/v2/team/members`,
        method: 'DELETE',
        headers: { 'X-PANOPTICON-TEAM': `${payload.teamId}` },
        body: payload.userIds,
      }),
    }),
  }),
});

export const {
  useGetTeamQuery,
  useGetTeamsQuery,
  useGetTeamServicesQuery,
  usePutTeamMutation,
  useGetOwnerDataQuery,
  useDeactivateTeamMutation,
  useGrantServiceMutation,
  useRevokeServiceMutation,
  useGetTeamMembersQuery,
  useGetTeamMembersSearchQuery,
  useGetTeamRolesQuery,
  useRevokeMembershipMutation,
  useSendMembershipInviteMutation,
  useValidateTeamMemberMutation,
  usePostTeamMemberMutation,
  usePostTeamMembersMutation,
  useRevokeMembersMutation,
  useGetTeamEventsQuery,
  usePostTeamMutation,
  usePutMembershipInvitationRequiredMutation,
  useGetOwnerTeamsQuery,
  useGetTeamNamesQuery,
  useUpdateTeamParentMutation,
  useGetTeamsSearchQuery,
} = teamApi;
