import { useGetOwnerDataQuery, useValidateTeamMemberMutation } from '@apis';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';
import { TeamMemberForm } from '@interfaces';
import { Autocomplete, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography } from '@mui/material';
import { setOwnersData } from '@slices';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose: () => void;
  open: boolean;
  teamId: number;
  onSubmit: (data: TeamMemberForm) => void;
  isAddingMember: boolean;
};

const formInitialValues = {
  email: '',
  givenName: '',
  familyName: '',
  title: '',
} as TeamMemberForm;

const AddTeamMember = ({ onClose, open, teamId, onSubmit, isAddingMember }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();

  const { ownerData } = useAppSelector((x) => x.team);
  const titleOptions = [
    t('TitleLabelDame'),
    t('TitleLabelDr'),
    t('TitleLabelMiss'),
    t('TitleLabelMr'),
    t('TitleLabelMrs'),
    t('TitleLabelMs'),
    t('TitleLabelMx'),
    t('TitleLabelProf'),
    t('TitleLabelSir'),
  ];
  const { userRights } = useAppSelector((x) => x.app);
  const [validateMember, { isLoading }] = useValidateTeamMemberMutation();
  const [isOwnerValidated, setIsOwnerValidated] = useState<boolean>(false);
  const handleMemberSubmit = (): void => {
    onSubmit(formData);
    onCancelClick();
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, resetForm } = useForm<TeamMemberForm>({
    validations: {
      email: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
        pattern: {   
          value: /^[a-zA-Z0-9._%+-]*[a-z\d']+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
          message: t('invalidEmail') },
      },
      givenName: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      familyName: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleMemberSubmit,
    initialValues: { ...formInitialValues },
  });

  useGetOwnerDataQuery({ teamId, searchTerm: formData.email }, { refetchOnMountOrArgChange: true, skip: !formData.email });

  useEffect(() => {
    dispatch(setOwnersData([]));
  }, []);

  const onCancelClick = () => {
    onClose();
    resetForm({ ...formInitialValues });
    setIsOwnerValidated(false);
    dispatch(setOwnersData([]));
  };

  useEffect(() => {
    if (open) setFormData({ ...formInitialValues });
  }, [open]);

  const onListSelection = (value: string) => {
    setFormData({ ...formData, email: value });
    if (value) {
      validateMember({ email: value, teamId })
        .unwrap()
        .then((data) => {
          setFormData({
            email: data.email,
            givenName: data.givenName,
            familyName: data.familyName,
            title: data.title,
          });
          setIsOwnerValidated(true);
        })
        .catch((err) => {
          console.debug('Failed while attempting to validate member', err);
          return;
        });
    }
  };

  const handleOnEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    handleChange(e);
    if (isOwnerValidated) {
      if (e.currentTarget.value && ownerData.length === 1 && e.currentTarget.value.toLocaleLowerCase().trim() !== ownerData[0].toLocaleLowerCase())
        setIsOwnerValidated(false);
    }
  };

  const handleOnEmailBlur = (): void => {
    if (ownerData.length === 1 && formData.email.toLocaleLowerCase().trim() === ownerData[0].toLocaleLowerCase()) onListSelection(formData.email);
  };

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      disableEscapeKeyDown
      aria-labelledby="form-dialog-title"
      fullWidth={true}>
      <DialogTitle id="dialog-title">{t('addTeamMemberFormPageTitle')}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Paper className="w-full p-5 space-y-4 mb-4">
            <Typography variant="h6" gutterBottom>
              {t('addTeamMemberFormPageMemberDetails')}
            </Typography>

            {userRights.isSysAdmin ? (
              <Autocomplete
                options={ownerData}
                getOptionLabel={(option) => option}
                onChange={(_event, value) => onListSelection(value ?? '')}
                value={formData.email}
                disabled={isLoading}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="email"
                    label={t('emailAddress')}
                    value={formData.email}
                    placeholder={t('emailAddress') as string}
                    variant="outlined"
                    autoComplete="emailaddress"
                    fullWidth={true}
                    onBlur={handleOnEmailBlur}
                    onChange={handleOnEmailChange}
                    {...(errors?.email && { error: true, helperText: errors.email })}
                  />
                )}
              />
            ) : (
              <TextField
                disabled={isLoading}
                name="email"
                label={t('emailAddress')}
                value={formData.email}
                placeholder={t('emailAddress') as string}
                variant="outlined"
                autoComplete="emailaddress"
                fullWidth={true}
                onBlur={handleOnEmailBlur}
                onChange={handleOnEmailChange}
                {...(errors?.email && { error: true, helperText: errors.email })}
              />
            )}
          </Paper>

          <Paper className="w-full p-5 space-y-4">
            <Autocomplete
              freeSolo
              autoSelect
              options={titleOptions}
              disabled={isLoading || isOwnerValidated}
              onChange={(event, newValue) => handleChange(event, false, newValue as string, 'title')}
              value={formData.title}
              renderInput={(params) => <TextField {...params} label={t('title')} variant="outlined" fullWidth />}
            />
            <TextField
              name="givenName"
              autoComplete="givenName"
              label={t('givenName')}
              placeholder={t('givenName') as string}
              fullWidth
              value={formData.givenName}
              onChange={handleChange}
              disabled={isLoading || isOwnerValidated}
              variant="outlined"
              {...(errors?.givenName && { error: true, helperText: errors.givenName })}
            />

            <TextField
              name="familyName"
              autoComplete="familyName"
              label={t('familyName')}
              placeholder={t('familyName') as string}
              fullWidth
              value={formData.familyName}
              onChange={handleChange}
              disabled={isLoading || isOwnerValidated}
              variant="outlined"
              {...(errors?.familyName && { error: true, helperText: errors.familyName })}
            />
          </Paper>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onCancelClick} disabled={isAddingMember}>
            {t('cancelButtonText')}
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={isAddingMember}>
            {isAddingMember && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddTeamMember;
