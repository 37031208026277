import LexacomLayout from '../components/shared/lexacomLayout';
import { RouteType } from '@interfaces';
import { RoutePath } from '@constants';
import {
  Home,
  Auth,
  Unauthorized401,
  NotFound404,
  TeamList,
  Team,
  TeamRole,
  UserList,
  User,
  UserDashboard,
  ChangePassword,
  ConfirmAccount,
  MultifactorAuthentication,
  TeamConfiguration,
  TeamEchoConfiguration,
  TeamWorkerPools,
  EchoCommands,
  EchoFeedback,
  RoleList,
  TeamWorkFlows,
  TeamWorkflow,
  Substitution,
  Substitutions,
  WorkflowTemplate,
  WorkflowTemplates,
  Report,
} from '@pages';

export const routes: RouteType[] = [
  { path: RoutePath.NotFound404, element: NotFound404, isAuthRequired: false },
  { path: RoutePath.Unauthorized401, element: Unauthorized401, isAuthRequired: false },
  { path: RoutePath.Home, element: Home, layout: LexacomLayout, isAuthRequired: false },
  { path: RoutePath.Auth, element: Auth, layout: LexacomLayout, isAuthRequired: false },
  { path: RoutePath.TeamsPath, element: TeamList, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamPath, element: Team, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamRolePath, element: TeamRole, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UsersPath, element: UserList, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UserPath, element: User, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UserAccountPath, element: UserDashboard, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UserChangePasswordPath, element: ChangePassword, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UserConfirmPath, element: ConfirmAccount, layout: LexacomLayout, isAuthRequired: false },
  { path: RoutePath.UserMfaPath, element: MultifactorAuthentication, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UserMfaRecoveryPath, element: MultifactorAuthentication, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UserMfaPathTeamRedirect, element: MultifactorAuthentication, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamConfigPath, element: TeamConfiguration, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamEchoConfigPath, element: TeamEchoConfiguration, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamRulesPath, element: TeamWorkerPools, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamFlowsPath, element: TeamWorkFlows, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.UserCommandsPath, element: EchoCommands, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.AdminCommandsPath, element: EchoCommands, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamCommandsPath, element: EchoCommands, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.AdminFeedbackPath, element: EchoFeedback, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.RolesPath, element: RoleList, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamWorkFlowPath, element: TeamWorkflow, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.TeamWorkFlowEditPath, element: TeamWorkflow, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.SubstitutionPath, element: Substitution, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.SubstitutionsPath, element: Substitutions, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.WorkflowTemplatePath, element: WorkflowTemplate, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.WorkflowTemplatesPath, element: WorkflowTemplates, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
  { path: RoutePath.ReportPath, element: Report, layout: LexacomLayout, isAuthRequired: true, redirectPath: RoutePath.Home },
];
