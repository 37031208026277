import React from 'react';
import { Autocomplete, TextField, Chip, Grid, ToggleButton, ToggleButtonGroup, Typography, Box } from '@mui/material';
import { WorkflowTemplateDto } from '@interfaces';
import { useTranslation } from 'react-i18next';

type LogicOperator = 'AND' | 'OR' | 'NOT';

interface WorkflowTemplatesFilterProps {
  workflows: WorkflowTemplateDto[];
  selectedLabels: string[];
  logicOperator: LogicOperator;
  onFilterChange: (newLabels: string[]) => void;
  onLogicChange: (newLogic: LogicOperator) => void;
}

const WorkflowTemplatesFilter = ({ workflows, selectedLabels, logicOperator, onFilterChange, onLogicChange }: WorkflowTemplatesFilterProps) => {
  const { t } = useTranslation('pano');

  const allLabels = Array.from(
    new Set(
      workflows.reduce((acc: string[], workflow) => {
        return [...acc, ...(workflow.labels || [])];
      }, []),
    ),
  ).sort();

  const handleLogicChange = (_event: React.MouseEvent<HTMLElement>, newLogic: LogicOperator) => {
    if (newLogic !== null) {
      onLogicChange(newLogic);
    }
  };

  const getChipColor = (label: string) => {
    if (logicOperator === 'NOT') return 'error';
    return 'default';
  };

  const getFilterDescription = () => {
    if (selectedLabels.length === 0) return '';

    switch (logicOperator) {
      case 'AND':
        return t('mustHaveAllLabels');
      case 'OR':
        return t('mustHaveAnyLabel');
      case 'NOT':
        return t('mustNotHaveLabels');
      default:
        return '';
    }
  };

  return (
    <Grid container item xs={12} md={6}>
      <Grid item xs={12} className="!mb-3">
        <Box display="flex" alignItems="center" gap={2}>
          <ToggleButtonGroup value={logicOperator} exclusive onChange={handleLogicChange} aria-label="label logic" size="small">
            <ToggleButton value="AND" aria-label="AND logic">
              AND
            </ToggleButton>
            <ToggleButton value="OR" aria-label="OR logic">
              OR
            </ToggleButton>
            <ToggleButton value="NOT" aria-label="NOT logic">
              NOT
            </ToggleButton>
          </ToggleButtonGroup>
          <Typography variant="body2" color="textSecondary">
            {getFilterDescription()}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} className="!mb-3">
        <Autocomplete
          multiple
          id="workflow-labels-filter"
          options={allLabels}
          value={selectedLabels}
          onChange={(_event, newValue) => onFilterChange(newValue)}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return <Chip variant="filled" label={option} key={key} color={getChipColor(option)} {...tagProps} />;
            })
          }
          renderInput={(params) => <TextField {...params} variant="outlined" label={t('filterByLabels')} placeholder={t('selectLabelsToFilter') as string} />}
          clearOnBlur
          clearOnEscape
        />
      </Grid>
    </Grid>
  );
};

export default WorkflowTemplatesFilter;
