import React, { useState, useRef, useMemo, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  FormControl,
  InputLabel,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, PlayArrow as RunIcon, ExpandMore as ExpandMoreIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useGetTemplatesQuery, useCreateTemplateMutation, useUpdateTemplateMutation, useRunTemplateMutation, useGetDatabaseSchemaQuery } from '@apis';
import { setSelectedColumns, clearGeneratedReport } from '@slices';
import { Template, QueryDefinition, JoinDefinition, TableInfo, ColumnInfo, FilterGroup, QueryType, ReportColumnDto } from '@interfaces';
import MaterialTable from '@material-table/core';
import FilterCreation from './FilterCreation';
import TemplateSchedules from './TemplateSchedules';
import { useTranslation } from 'react-i18next';

interface PotentialJoin {
  leftTable: string;
  rightTable: string;
  leftColumn: string;
  rightColumn: string;
}
const TemplateManagement: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data: templates, isLoading: templatesLoading, refetch } = useGetTemplatesQuery();
  const { data: schema, isLoading: schemaLoading } = useGetDatabaseSchemaQuery();
  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const [runTemplate] = useRunTemplateMutation();
  const { t } = useTranslation('pano');
  const { selectedColumns, filters } = useAppSelector((state) => state.report);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRunDialog, setOpenRunDialog] = useState(false);
  const [dialogMode, setDialogMode] = useState<'create' | 'edit'>('create');
  const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
  const [templateName, setTemplateName] = useState('');
  const [joins, setJoins] = useState<JoinDefinition[]>([]);
  const [filterableColumns, setFilterableColumns] = useState<string[]>([]);
  const [runFilters, setRunFilters] = useState<FilterGroup[]>([]);
  const [logicalOperators, setLogicalOperators] = useState<('AND' | 'OR')[]>(runFilters.map(() => 'AND'));
  const [tabValue, setTabValue] = useState(0);
  const [expandedTables, setExpandedTables] = useState<string[]>([]);
  const tableRef = useRef(null);
  const [queryType, setQueryType] = useState<QueryType>({ type: 'SELECT' });

  const [customJoin, setCustomJoin] = useState({
    leftTable: '',
    leftColumn: '',
    rightTable: '',
    rightColumn: '',
    joinType: 'INNER',
  });

  const selectedTables = useMemo(() => [...new Set(selectedColumns.map((col) => col.tableName))], [selectedColumns]);

  const getColumnsForTable = (tableName: string) => {
    const tableInfo = schema?.find((t) => t.tableName === tableName);
    return tableInfo?.columns.map((c) => c.columnName) || [];
  };

  const isCustomJoinValid = () => {
    return customJoin.leftTable && customJoin.leftColumn && customJoin.rightTable && customJoin.rightColumn;
  };

  const handleAddCustomJoin = () => {
    if (isCustomJoinValid()) {
      setJoins([...joins, { ...customJoin, joinType: 'INNER' }]);
      setCustomJoin({
        leftTable: '',
        leftColumn: '',
        rightTable: '',
        rightColumn: '',
        joinType: 'INNER',
      });
    }
  };

  const findPotentialJoins = (schema: TableInfo[]): PotentialJoin[] => {
    const potentialJoins: PotentialJoin[] = [];

    for (const table of schema) {
      for (const column of table.columns) {
        if (column.columnName.toLowerCase().endsWith('id') && column.columnName.toLowerCase() !== 'id') {
          const possibleTableName = `${column.columnName.toLowerCase().slice(0, -2)}s`;
          const referencedTable = schema.find((t) => t.tableName.toLowerCase() === possibleTableName);

          if (referencedTable) {
            potentialJoins.push({
              leftTable: table.tableName,
              rightTable: referencedTable.tableName,
              leftColumn: column.columnName,
              rightColumn: 'id',
            });
          }
        }
      }
    }

    return potentialJoins;
  };
  const potentialJoins = useMemo(() => {
    if (!schema) return [];
    return findPotentialJoins(schema);
  }, [schema]);

  const getRelevantJoins = useMemo(() => {
    const selectedTables = new Set(selectedColumns.map((col) => col.tableName));
    const allPotentialJoins = findPotentialJoins(schema ?? []);

    const areTablesConnected = (table1: string, table2: string, visited = new Set<string>()): boolean => {
      if (visited.has(table1)) return false;
      visited.add(table1);

      const directConnection = joins.some(
        (join) => (join.leftTable === table1 && join.rightTable === table2) || (join.rightTable === table1 && join.leftTable === table2),
      );
      if (directConnection) return true;

      const connectedTables = joins
        .filter((join) => join.leftTable === table1 || join.rightTable === table1)
        .map((join) => (join.leftTable === table1 ? join.rightTable : join.leftTable));

      return connectedTables.some((table) => areTablesConnected(table, table2, visited));
    };

    return allPotentialJoins.filter((join) => {
      if (!selectedTables.has(join.leftTable) || !selectedTables.has(join.rightTable)) {
        return false;
      }

      if (areTablesConnected(join.leftTable, join.rightTable)) {
        return false;
      }

      return true;
    });
  }, [selectedColumns, schema, joins]);

  useEffect(() => {
    const newPotentialJoins = getRelevantJoins.filter(
      (potential) =>
        !joins.some(
          (existing) =>
            (existing.leftTable === potential.leftTable &&
              existing.rightTable === potential.rightTable &&
              existing.leftColumn === potential.leftColumn &&
              existing.rightColumn === potential.rightColumn) ||
            (existing.leftTable === potential.rightTable &&
              existing.rightTable === potential.leftTable &&
              existing.leftColumn === potential.rightColumn &&
              existing.rightColumn === potential.leftColumn),
        ),
    );

    if (newPotentialJoins.length > 0) {
      setJoins((prev) => [
        ...prev,
        ...newPotentialJoins.map((join) => ({
          ...join,
          joinType: 'INNER' as const,
        })),
      ]);
    }
  }, [getRelevantJoins]);

  const handleColumnSelect = (tableName: string, columnName: string) => {
    const isSelected = selectedColumns.some((col) => col.tableName === tableName && col.columnName === columnName);
    const newSelectedColumns = isSelected
      ? selectedColumns.filter((col) => !(col.tableName === tableName && col.columnName === columnName))
      : [...selectedColumns, { tableName, columnName }];
    dispatch(setSelectedColumns(newSelectedColumns));
  };

  const handleCreateTemplate = () => {
    setDialogMode('create');
    setCurrentTemplate(null);
    setTemplateName('');
    setJoins([]);
    setFilterableColumns([]);
    dispatch(setSelectedColumns([]));
    setTabValue(0);
    setOpenDialog(true);
  };

  const handleEditTemplate = (template: Template) => {
    setDialogMode('edit');
    setCurrentTemplate(template);
    setTemplateName(template.name);
    console.warn(template.query.joins, 'template');
    setJoins(template.query.joins);
    setQueryType(template.query.queryType || { type: 'SELECT' });
    setFilterableColumns(
      template.query.tables.flatMap((table) => table.columns.filter((col) => col.isFilterable).map((col) => `${table.tableName}.${col.columnName}`)),
    );
    dispatch(
      setSelectedColumns(
        template.query.tables.flatMap((table) =>
          table.columns.map((column) => ({ tableName: table.tableName, columnName: column.columnName, alias: column.alias })),
        ),
      ),
    );
    setTabValue(0);
    setOpenDialog(true);
  };

  const handleSaveTemplate = async () => {
    const queryDefinition: QueryDefinition = {
      tables: selectedColumns.reduce((acc, col) => {
        const tableIndex = acc.findIndex((t) => t.tableName === col.tableName);
        if (tableIndex === -1) {
          acc.push({
            tableName: col.tableName,
            columns: [
              {
                columnName: col.columnName,
                isFilterable: filterableColumns.includes(`${col.tableName}.${col.columnName}`),
                filterType: 'Equals',
                alias: col.alias,
              },
            ],
          });
        } else {
          acc[tableIndex].columns.push({
            columnName: col.columnName,
            isFilterable: filterableColumns.includes(`${col.tableName}.${col.columnName}`),
            filterType: 'Equals',
            alias: col.alias,
          });
        }
        return acc;
      }, [] as QueryDefinition['tables']),
      joins: joins,
      queryType: queryType,
    };

    const templateData = {
      name: templateName,
      version: 1,
      query: queryDefinition,
    };

    if (dialogMode === 'create') {
      await createTemplate(templateData);
    } else if (currentTemplate) {
      await updateTemplate({ id: currentTemplate.id, ...templateData });
    }
    refetch();
    setOpenDialog(false);
  };

  const handleRunTemplate = async (templateId: number) => {
    const templateToRun = templates?.find((t) => t.id === templateId);
    if (templateToRun) {
      setCurrentTemplate(templateToRun);
      setRunFilters([]);
      setLogicalOperators([]);
      dispatch(clearGeneratedReport());
      setOpenRunDialog(true);
    }
  };

  const executeRunTemplate = async () => {
    if (currentTemplate) {
      await runTemplate({ templateId: currentTemplate.id, filterInfo: { groups: runFilters, interGroupOperators: logicalOperators.slice(0, -1) } });
      setOpenRunDialog(false);
    }
  };

  const handleAddJoin = (join: PotentialJoin) => {
    setJoins([...joins, { ...join, joinType: 'INNER' }]);
  };

  const handleJoinChange = (index: number, field: keyof JoinDefinition, value: string) => {
    const newJoins = [...joins];
    newJoins[index] = { ...newJoins[index], [field]: value };
    setJoins(newJoins);
  };

  const handleRemoveJoin = (index: number) => {
    setJoins(joins.filter((_, i) => i !== index));
  };

  const handleFilterableChange = (columnFullName: string) => {
    setFilterableColumns((prev) => (prev.includes(columnFullName) ? prev.filter((col) => col !== columnFullName) : [...prev, columnFullName]));
  };

  const handleTableExpand = (tableName: string) => {
    setExpandedTables((prev) => (prev.includes(tableName) ? prev.filter((t) => t !== tableName) : [...prev, tableName]));
  };

  const getSelectedColumnCount = (tableName: string) => {
    return selectedColumns.filter((col) => col.tableName === tableName).length;
  };

  const renderColumnSelection = () => {
    return (
      <Box>
        <Typography variant="h6" className="mb-2">
          Select Columns
        </Typography>
        {schema?.map((table: TableInfo) => (
          <Accordion key={table.tableName} expanded={expandedTables.includes(table.tableName)} onChange={() => handleTableExpand(table.tableName)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${table.tableName}-content`} id={`${table.tableName}-header`}>
              <Typography>{table.tableName}</Typography>
              <Chip label={`${getSelectedColumnCount(table.tableName)} selected`} size="small" color="primary" className="ml-2" />
            </AccordionSummary>
            <AccordionDetails>
              <Box className="ml-4">
                {table.columns.map((column: ColumnInfo) => (
                  <FormControlLabel
                    key={`${table.tableName}-${column.columnName}`}
                    control={
                      <Checkbox
                        checked={selectedColumns.some((col) => col.tableName === table.tableName && col.columnName === column.columnName)}
                        onChange={() => handleColumnSelect(table.tableName, column.columnName)}
                      />
                    }
                    label={`${column.columnName} (${column.dataType})`}
                  />
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    );
  };

  const renderJoinConfiguration = () => {
    const unusedPotentialJoins = potentialJoins.filter(
      (potential) =>
        !joins.some(
          (existing) =>
            (existing.leftTable === potential.leftTable &&
              existing.rightTable === potential.rightTable &&
              existing.leftColumn === potential.leftColumn &&
              existing.rightColumn === potential.rightColumn) ||
            (existing.leftTable === potential.rightTable &&
              existing.rightTable === potential.leftTable &&
              existing.leftColumn === potential.rightColumn &&
              existing.rightColumn === potential.leftColumn),
        ),
    );

    return (
      <Box className="space-y-6">
        <Typography variant="h6" className="mb-4">
          Configure Table Joins
        </Typography>

        {selectedColumns.length === 0 ? (
          <Paper variant="outlined" className="p-8 text-center">
            <Typography color="textSecondary" className="mb-2">
              {t('noColumnsSelected')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t('selectColumnsPrompt')}
            </Typography>
          </Paper>
        ) : (
          <>
            <Box className="space-y-3">
              {joins.map((join, index) => (
                <Paper key={index} className="p-4 border border-gray-200" variant="outlined">
                  <Box className="flex items-center justify-between">
                    <Box className="flex-1">
                      <Box className="flex items-center gap-2 mb-2">
                        <Typography className="font-medium text-sm">{`${join.leftTable}.${join.leftColumn}`}</Typography>
                        <Typography color="textSecondary" className="text-sm">
                          =
                        </Typography>
                        <Typography className="font-medium text-sm">{`${join.rightTable}.${join.rightColumn}`}</Typography>
                      </Box>
                      <FormControl size="small" className="min-w-[120px]">
                        <Select value={join.joinType} onChange={(e) => handleJoinChange(index, 'joinType', e.target.value as string)}>
                          <MenuItem value="INNER">INNER JOIN</MenuItem>
                          <MenuItem value="LEFT">LEFT JOIN</MenuItem>
                          <MenuItem value="RIGHT">RIGHT JOIN</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <IconButton onClick={() => handleRemoveJoin(index)} color="error" size="small">
                      <DeleteOutlineIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Paper>
              ))}
            </Box>

            {unusedPotentialJoins.length > 0 && (
              <Box className="mt-6">
                <Typography variant="subtitle1" className="mb-3 font-medium">
                  Suggested Joins
                </Typography>
                <Box className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  {unusedPotentialJoins.map((join, index) => (
                    <Paper
                      key={index}
                      variant="outlined"
                      className="p-3 border border-gray-200 hover:border-blue-500 transition-colors cursor-pointer"
                      onClick={() => handleAddJoin(join)}>
                      <Box className="flex items-center justify-between">
                        <Box>
                          <Typography className="text-sm">
                            {join.leftTable}
                            <span className="text-gray-500 mx-1">→</span>
                            {join.rightTable}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {`${join.leftColumn} = ${join.rightColumn}`}
                          </Typography>
                        </Box>
                        <AddIcon color="primary" fontSize="small" />
                      </Box>
                    </Paper>
                  ))}
                </Box>
              </Box>
            )}

            <Paper className="mt-6 p-4" variant="outlined">
              <Typography variant="subtitle1" className="mb-3 font-medium">
                Add Custom Join
              </Typography>
              <Box className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <FormControl fullWidth size="small">
                  <InputLabel>Left Table</InputLabel>
                  <Select value={customJoin.leftTable || ''} onChange={(e) => setCustomJoin({ ...customJoin, leftTable: e.target.value })} label="Left Table">
                    {selectedTables.map((table) => (
                      <MenuItem key={table} value={table}>
                        {table}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Left Column</InputLabel>
                  <Select
                    value={customJoin.leftColumn || ''}
                    onChange={(e) => setCustomJoin({ ...customJoin, leftColumn: e.target.value })}
                    label="Left Column"
                    disabled={!customJoin.leftTable}>
                    {getColumnsForTable(customJoin.leftTable).map((col) => (
                      <MenuItem key={col} value={col}>
                        {col}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Right Table</InputLabel>
                  <Select
                    value={customJoin.rightTable || ''}
                    onChange={(e) => setCustomJoin({ ...customJoin, rightTable: e.target.value })}
                    label="Right Table">
                    {selectedTables.map((table) => (
                      <MenuItem key={table} value={table}>
                        {table}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth size="small">
                  <InputLabel>Right Column</InputLabel>
                  <Select
                    value={customJoin.rightColumn || ''}
                    onChange={(e) => setCustomJoin({ ...customJoin, rightColumn: e.target.value })}
                    label="Right Column"
                    disabled={!customJoin.rightTable}>
                    {getColumnsForTable(customJoin.rightTable).map((col) => (
                      <MenuItem key={col} value={col}>
                        {col}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box className="mt-4 flex justify-end">
                <Button onClick={handleAddCustomJoin} disabled={!isCustomJoinValid()} variant="contained" startIcon={<AddIcon />}>
                  Add Join
                </Button>
              </Box>
            </Paper>
          </>
        )}
      </Box>
    );
  };

  const renderFilterableColumnSelection = () => {
    return (
      <Box>
        {selectedColumns.length > 0 ? (
          <>
            <Typography variant="h6" className="mb-2">
              Select Filterable Columns
            </Typography>
            {selectedColumns.map((col) => (
              <FormControlLabel
                key={`${col.tableName}.${col.columnName}`}
                control={
                  <Checkbox
                    checked={filterableColumns.includes(`${col.tableName}.${col.columnName}`)}
                    onChange={() => handleFilterableChange(`${col.tableName}.${col.columnName}`)}
                  />
                }
                label={col.alias ?? `${col.tableName}.${col.columnName}`}
              />
            ))}
          </>
        ) : (
          <Paper variant="outlined" className="p-8 text-center">
            <Typography color="textSecondary" className="mb-2">
              {t('noColumnsSelected')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t('selectColumnsPrompt')}
            </Typography>
          </Paper>
        )}
      </Box>
    );
  };

  const renderColumnNaming = () => {
    const groupedColumns = selectedColumns.reduce((acc, col) => {
      acc[col.tableName] = acc[col.tableName] || [];
      acc[col.tableName].push(col);
      return acc;
    }, {} as Record<string, typeof selectedColumns>);

    const isAnySameName = selectedColumns.some((col) =>
      selectedColumns.some((otherCol) => col !== otherCol && (col.alias || col.columnName) === (otherCol.alias || otherCol.columnName)),
    );
    console.warn(selectedColumns);
    return (
      <Box>
        <Box className="flex justify-between items-center mb-4">
          <Typography variant="h6">{t('columnDisplayNames')}</Typography>
          {isAnySameName && (
            <Typography color="error" variant="caption">
              {t('duplicateNameWarning')}
            </Typography>
          )}
        </Box>

        {Object.keys(groupedColumns).length > 0 ? (
          Object.entries(groupedColumns).map(([tableName, columns]) => (
            <Paper key={tableName} className="p-4 mb-4" variant="outlined">
              <Typography variant="h6" className="mb-2">
                {t('table')}: {tableName}
              </Typography>
              {columns.map((col) => {
                const isDuplicate = selectedColumns.some(
                  (otherCol) => col !== otherCol && (col.alias || col.columnName) === (otherCol.alias || otherCol.columnName),
                );

                return (
                  <Box key={`${col.tableName}-${col.columnName}`} className="mb-4">
                    <Box className="flex flex-col sm:flex-row items-start sm:items-center">
                      <Box className="flex-1 sm:mr-4">
                        <Typography variant="subtitle2" color="textSecondary">
                          {t('originalColumnName')}: {col.columnName}
                        </Typography>
                      </Box>
                      <Box className="flex-1">
                        <TextField
                          size="small"
                          label={t('displayName')}
                          fullWidth
                          value={col.alias === undefined ? '' : col.alias}
                          error={isDuplicate}
                          helperText={isDuplicate ? t('duplicateNameError') : ''}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            const newSelectedColumns = selectedColumns.map((c) => {
                              if (c.tableName === col.tableName && c.columnName === col.columnName) {
                                return {
                                  ...c,
                                  alias: newValue.trim() === '' ? undefined : newValue,
                                };
                              }
                              return c;
                            });

                            dispatch(setSelectedColumns(newSelectedColumns));
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
            </Paper>
          ))
        ) : (
          <Paper variant="outlined" className="p-8 text-center">
            <Typography color="textSecondary" className="mb-2">
              {t('noColumnsSelected')}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t('selectColumnsPrompt')}
            </Typography>
          </Paper>
        )}
      </Box>
    );
  };

  const renderQueryType = () => (
    <Box className="space-y-4">
      <Paper variant="outlined" className="p-4">
        <Typography variant="subtitle1" className="mb-3">
          Main Query Type
        </Typography>
        <FormControl fullWidth size="small">
          <Select value={queryType?.type || 'SELECT'} onChange={(e) => setQueryType({ type: e.target.value as 'SELECT' | 'COUNT' })}>
            <MenuItem value="SELECT">Select with Aggregations</MenuItem>
            <MenuItem value="COUNT">Count All Records</MenuItem>
          </Select>
          <FormHelperText>Choose the main type of query</FormHelperText>
        </FormControl>
      </Paper>

      {queryType?.type === 'SELECT' && (
        <Paper variant="outlined" className="p-4">
          <Typography variant="subtitle1" className="mb-3">
            Column Settings
          </Typography>
          {selectedColumns.map((col) => (
            <Box key={`${col.tableName}-${col.columnName}`} className="flex items-center justify-between gap-2 mb-2">
              <Typography className="min-w-[200px]">{col.alias || `${col.tableName}.${col.columnName}`}</Typography>
              <FormControl size="small" className="min-w-[150px]">
                <Select
                  value={
                    queryType?.columnAggregations?.find((a) => a.tableName === col.tableName && a.columnName === col.columnName)?.aggregateFunction || 'NONE'
                  }
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setQueryType((prev) => {
                      const newAggs = [...(prev.columnAggregations || [])];
                      const existingIndex = newAggs.findIndex((a) => a.tableName === col.tableName && a.columnName === col.columnName);

                      const otherColumns = selectedColumns.filter((c) => c !== col).map((c) => `${c.tableName}.${c.columnName}`);

                      if (newValue === 'NONE') {
                        if (existingIndex > -1) {
                          newAggs.splice(existingIndex, 1);
                        }
                      } else {
                        const agg = {
                          tableName: col.tableName,
                          columnName: col.columnName,
                          aggregateFunction: newValue,
                        };
                        if (existingIndex > -1) {
                          //@ts-ignore
                          newAggs[existingIndex] = agg;
                        } else {
                          //@ts-ignore
                          newAggs.push(agg);
                        }
                      }

                      const updatedGroupBy = newAggs.length > 0 ? otherColumns : [];

                      return {
                        ...prev,
                        columnAggregations: newAggs,
                        groupByColumns: updatedGroupBy,
                      };
                    });
                  }}>
                  <MenuItem value="NONE">No Aggregation</MenuItem>
                  <MenuItem value="COUNT">Count</MenuItem>
                  <MenuItem value="SUM">Sum</MenuItem>
                  <MenuItem value="AVG">Average</MenuItem>
                  <MenuItem value="MIN">Minimum</MenuItem>
                  <MenuItem value="MAX">Maximum</MenuItem>
                </Select>
              </FormControl>
            </Box>
          ))}
        </Paper>
      )}

      <Paper variant="outlined" className="p-4">
        <Box className="flex justify-between items-center mb-3">
          <Typography variant="subtitle1">Order By</Typography>
          <Button
            size="small"
            startIcon={<AddIcon />}
            onClick={() => {
              setQueryType((prev) => ({
                ...prev,
                orderBy: [
                  ...(prev.orderBy || []),
                  {
                    tableName: selectedColumns[0].tableName,
                    columnName: selectedColumns[0].columnName,
                    direction: 'ASC',
                  },
                ],
              }));
            }}>
            Add Sorting
          </Button>
        </Box>

        {queryType?.type === 'SELECT' &&
          queryType?.orderBy?.map((orderBy, index) => (
            <Box key={index} className="flex items-center gap-2 mb-2">
              <FormControl size="small" fullWidth>
                <Select
                  value={`${orderBy.tableName}.${orderBy.columnName}`}
                  onChange={(e) => {
                    const [tableName, columnName] = e.target.value.split('.');
                    const newOrderBy = [...(queryType.orderBy || [])];
                    newOrderBy[index] = { ...newOrderBy[index], tableName, columnName };
                    setQueryType((prev) => ({ ...prev, orderBy: newOrderBy }));
                  }}>
                  {selectedColumns.map((col) => (
                    <MenuItem key={`${col.tableName}.${col.columnName}`} value={`${col.tableName}.${col.columnName}`}>
                      {col.alias || `${col.tableName}.${col.columnName}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl size="small">
                <Select
                  value={orderBy.direction}
                  onChange={(e) => {
                    const newOrderBy = [...(queryType.orderBy || [])];
                    newOrderBy[index] = { ...newOrderBy[index], direction: e.target.value as 'ASC' | 'DESC' };
                    setQueryType((prev) => ({ ...prev, orderBy: newOrderBy }));
                  }}>
                  <MenuItem value="ASC">Ascending</MenuItem>
                  <MenuItem value="DESC">Descending</MenuItem>
                </Select>
              </FormControl>

              <IconButton
                size="small"
                onClick={() => {
                  const newOrderBy = queryType?.orderBy?.filter((_, i) => i !== index) || [];
                  setQueryType((prev) => ({ ...prev, orderBy: newOrderBy }));
                }}>
                <DeleteOutlineIcon />
              </IconButton>
            </Box>
          ))}
      </Paper>
      <Paper variant="outlined" className="p-4">
        <Box>
          <Typography variant="subtitle1" className="mb-2">
            Query Preview
          </Typography>
          <Typography variant="body2" className="font-mono p-2 rounded">
            {generateQueryPreview(queryType, selectedColumns)}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );

  const generateQueryPreview = (queryType: QueryType, selectedColumns: ReportColumnDto[]) => {
    if (queryType.type === 'COUNT') {
      return 'SELECT COUNT(*) FROM ...';
    }

    let preview = 'SELECT ';
    const selectParts = [] as any;

    selectedColumns.forEach((col) => {
      const agg = queryType.columnAggregations?.find((a) => a.tableName === col.tableName && a.columnName === col.columnName);

      if (!agg) {
        selectParts.push(`${col.tableName}.${col.columnName}`);
      }
    });

    queryType.columnAggregations?.forEach((agg) => {
      selectParts.push(`${agg.aggregateFunction}(${agg.tableName}.${agg.columnName})`);
    });

    preview += selectParts.join(', ');

    if (queryType.groupByColumns?.length) {
      preview += '\nGROUP BY ' + queryType.groupByColumns.join(', ');
    }

    if (queryType.orderBy?.length) {
      preview += '\nORDER BY ' + queryType.orderBy.map((o) => `${o.tableName}.${o.columnName} ${o.direction}`).join(', ');
    }

    return preview;
  };

  if (templatesLoading || schemaLoading) return <Typography>Loading...</Typography>;

  return (
    <Paper className="p-4 mb-4">
      <Button startIcon={<AddIcon />} onClick={handleCreateTemplate} className="mb-4">
        Create New Template
      </Button>
      <MaterialTable
        title="Templates"
        columns={[
          { title: 'ID', field: 'id' },
          { title: 'Name', field: 'name' },
          { title: 'Version', field: 'version' },
        ]}
        data={templates || []}
        actions={[
          {
            icon: () => <EditIcon />,
            tooltip: 'Edit Template',
            onClick: (event, rowData) => handleEditTemplate(rowData as Template),
          },
          {
            icon: () => <RunIcon />,
            tooltip: 'Run Template',
            onClick: (event, rowData) => handleRunTemplate((rowData as Template).id),
          },
        ]}
        detailPanel={[
          {
            tooltip: 'Show Schedules',
            render: (rowData) => {
              const template = rowData.rowData as Template;
              const availableColumns = template.query.tables.flatMap((table) =>
                table.columns
                  .filter((col) => col.isFilterable)
                  .map((col) => ({
                    tableName: table.tableName,
                    columnName: col.columnName,
                    dataType:
                      schema?.find((s) => s.tableName === table.tableName)?.columns.find((sc) => sc.columnName === col.columnName)?.dataType || 'unknown',
                    filterType: col.filterType,
                    alias: col.alias,
                  })),
              );

              return (
                <Box className="p-4">
                  <TemplateSchedules templateId={template.id} availableColumns={availableColumns} availableEmails={[]} />
                </Box>
              );
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
          detailPanelType: 'single',
        }}
        isLoading={templatesLoading}
        tableRef={tableRef}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>{dialogMode === 'create' ? 'Create New Template' : 'Edit Template'}</DialogTitle>
        <DialogContent>
          <TextField autoFocus margin="dense" label="Template Name" fullWidth value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
          <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} className="mt-4">
            <Tab label="Columns" />
            <Tab label="Query Type" />
            <Tab label="Column Names" />
            <Tab label="Joins" />
            <Tab label="Filters" />
          </Tabs>
          <Box className="mt-2">
            {tabValue === 0 && renderColumnSelection()}
            {tabValue === 1 && renderQueryType()}
            {tabValue === 2 && renderColumnNaming()}
            {tabValue === 3 && renderJoinConfiguration()}
            {tabValue === 4 && renderFilterableColumnSelection()}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSaveTemplate}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openRunDialog} onClose={() => setOpenRunDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>Run Template: {currentTemplate?.name}</DialogTitle>
        <DialogContent>
          <Typography variant="h6" className="pb-2">
            Add Filters (Optional)
          </Typography>
          <FilterCreation
            filterGroups={runFilters}
            setFilterGroups={setRunFilters}
            interGroupOperators={logicalOperators}
            setInterGroupOperators={setLogicalOperators}
            availableColumns={
              currentTemplate?.query.tables.flatMap((t) =>
                t.columns
                  .filter((c) => c.isFilterable)
                  .map((c) => ({
                    tableName: t.tableName,
                    columnName: c.columnName,
                    dataType: schema?.find((s) => s.tableName === t.tableName)?.columns.find((sc) => sc.columnName === c.columnName)?.dataType || 'unknown',
                    filterType: c.filterType,
                    alias: c.alias,
                  })),
              ) || []
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => setOpenRunDialog(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={executeRunTemplate}>
            Run
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default TemplateManagement;
