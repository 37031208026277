import { useState } from 'react';
import { Modal, Button, Grid, Paper, Typography, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TeamSelector } from '@components';
import { TeamDetailedItem } from '@interfaces';
import { useAppDispatch, useAppSelector } from '@hooks';
import { openSnackbar } from '@slices';
import { useUpdateTeamParentMutation, useGetTeamsSearchQuery } from '@apis';
import { useDebounce } from 'use-debounce';
import { useAuth } from 'oidc-react';

type Props = {
  isOpen: boolean;
  currentTeam: TeamDetailedItem;
  onClose: () => void;
  refetch: () => void;
};

const AssignManagedTeamModal = ({ isOpen, currentTeam, onClose, refetch }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const [selectedTeam, setSelectedTeam] = useState<TeamDetailedItem | null>(null);
  const [searchTeam, setSearchTeam] = useState<string>('');
  const [debouncedSearchTeam] = useDebounce(searchTeam, 200);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const { userData } = useAuth();
  const { userRights } = useAppSelector((state) => state.app);
  const { teamsSearch } = useAppSelector((state) => state.team);

  const [updateTeamParent] = useUpdateTeamParentMutation();

  const { isLoading } = useGetTeamsSearchQuery(
    {
      pageNo: 0,
      pageSize: 20,
      searchTerm: debouncedSearchTeam,
      parentTeamId: userRights.sysAdminTeams[0],
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !(userData && userRights.isSysAdmin),
    },
  );

  const filteredTeams = teamsSearch.filter((team) => {
    console.warn(team);
    if (team.id === currentTeam.id || team.parentTeamId === currentTeam.id) {
      return false;
    }
    const validHierarchy = {
      Distributor: ['Reseller', 'Customer'],
      Reseller: ['Customer'],
      Customer: [] as string[],
    };

    const allowedChildTypes = validHierarchy[currentTeam.type as keyof typeof validHierarchy] || [];
    return allowedChildTypes.includes(team.type);
  });

  const handleAssignTeam = async () => {
    if (!selectedTeam || !userRights.isSysAdmin) return;

    try {
      if (selectedTeam.parentTeamId === 1) {
        await handleConfirmAssignment();
      } else {
        setIsConfirmOpen(true);
      }
    } catch (error) {
      dispatch(
        openSnackbar({
          message: t('errorAssigningTeam'),
          severity: 'error',
          display: true,
        }),
      );
    }
  };

  const handleConfirmAssignment = async () => {
    if (!selectedTeam) return;

    try {
      await updateTeamParent({
        teamId: selectedTeam.id,
        newParentId: currentTeam.id,
      }).unwrap();
      refetch();
      dispatch(
        openSnackbar({
          message: t('teamAssignedSuccessfully'),
          severity: 'success',
          display: true,
        }),
      );

      onClose();
      setSelectedTeam(null);
      setIsConfirmOpen(false);
    } catch (error) {
      dispatch(
        openSnackbar({
          message: t('errorAssigningTeam'),
          severity: 'error',
          display: true,
        }),
      );
    }
  };

  if (!userRights.isSysAdmin) return <></>;

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <Grid container className="h-screen">
          <Paper className="p-5 space-y-6 w-96 m-auto">
            <Typography variant="h6" gutterBottom>
              {t('assignExistingTeam')}
            </Typography>

            <Grid item xs={12}>
              <TeamSelector
                teams={filteredTeams}
                isLoading={isLoading}
                selectValue={selectedTeam}
                setSelectValue={setSelectedTeam}
                setSearchTeam={setSearchTeam}
              />
            </Grid>

            {selectedTeam && selectedTeam.parentTeamId !== 1 && <Alert severity="info">{t('teamHasExistingParent')}</Alert>}

            <Grid item xs={12} className="flex justify-end">
              <Button variant="contained" className="!mr-2" color="secondary" onClick={onClose}>
                {t('cancel')}
              </Button>
              <Button variant="contained" color="primary" onClick={handleAssignTeam} disabled={!selectedTeam || isLoading}>
                {isLoading ? <CircularProgress size={24} /> : t('assign')}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Modal>

      <Dialog open={isConfirmOpen} onClose={() => setIsConfirmOpen(false)}>
        <DialogTitle>{t('confirmTeamReassignment')}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('currentParentTeam')}: {selectedTeam?.parentTeamId}
          </Typography>
          <Typography>
            {t('newParentTeam')}: {currentTeam?.name}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsConfirmOpen(false)} color="secondary">
            {t('cancel')}
          </Button>
          <Button onClick={handleConfirmAssignment} color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AssignManagedTeamModal;
