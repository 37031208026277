import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '@hooks';
import { WorkflowStepFilterItemTableRow } from '@interfaces';
import MaterialTable from '@material-table/core';
import { Autocomplete, TextField } from '@mui/material';
import { green, red } from '@constants';
import { openSnackbar } from '@slices';
import React, { createRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableIcons } from '../shared';
import { v4 as uuidv4 } from 'uuid';

type Props = {
  worflowStepId: number;
  teamId: number;
  workflowId: number;
  tableData: WorkflowStepFilterItemTableRow[];
  setTableData: React.Dispatch<React.SetStateAction<WorkflowStepFilterItemTableRow[]>>;
};

const WorkflowStepFiltersEditor = ({ worflowStepId, tableData, setTableData }: Props) => {
  const tableRef = createRef() as React.RefObject<any> | React.MutableRefObject<undefined> | undefined;
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const { workerPools } = useAppSelector((x) => x.mahon);

  const workerPoolsLookup: any = {};
  const getWorkerPoolsLookup = (): any => {
    workerPools.map((item) => {
      workerPoolsLookup[item.id] = item.name;
    });
    return workerPoolsLookup;
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      icons={TableIcons}
      options={{
        tableLayout: 'auto',
        addRowPosition: 'first',
        sorting: false,
        filtering: false,
        emptyRowsWhenPaging: false,
        actionsColumnIndex: -1,
        actionsCellStyle: { padding: '0px 20px' },
      }}
      columns={[
        { title: t('definition'), field: 'definition', editable: 'never', initialEditValue: '[]' },
        { title: t('description'), field: 'description', editable: 'never', initialEditValue: '' },
        {
          title: t('isCategory'),
          field: 'isCategory',
          type: 'boolean',
          align: 'center',
          width: '150px',
          editable: 'never',
          initialEditValue: false,
          render: (rowData) =>
            rowData.isCategory ? (
              <FontAwesomeIcon icon={faCheck} style={{ color: green[500] }} />
            ) : (
              <FontAwesomeIcon icon={faTimes} style={{ color: red[500] }} />
            ),
        },
        {
          title: t('workerPoolId'),
          field: 'workerPoolId',
          lookup: getWorkerPoolsLookup(),
          editComponent: (props) => (
            <Autocomplete
              options={workerPools}
              getOptionLabel={(option) => option.name}
              autoComplete={false}
              value={workerPools.find((x) => x.id === props.value) ?? null}
              onChange={(_event, value) => props.onChange(value?.id)}
              renderInput={(params) => <TextField {...params} label={t('workerPools')} fullWidth />}
            />
          ),
        },
      ]}
      data={tableData.filter((x) => x.workflowStepId === worflowStepId)}
      title={t('stepFilters')}
      editable={{
        onRowAdd: (newData) =>
          new Promise<void>((resolve, reject) => {
            if (!newData.workerPoolId) {
              dispatch(openSnackbar({ message: `${t('workerPoolId')} ${t('fieldRequired')}`, severity: 'error', display: true }));
              reject();
            } else {
              const newRow = { ...newData, workflowStepId: worflowStepId, rowId: uuidv4() };
              setTableData([...tableData, newRow]);
              resolve();
            }
          }),
        onRowDelete: (oldData) =>
          new Promise<void>((resolve) => {
            const dataDelete = [...tableData];
            const index = tableData.findIndex((x) => x.rowId === oldData.rowId);
            dataDelete.splice(index, 1);
            setTableData([...dataDelete]);
            resolve();
          }),
      }}
    />
  );
};

export default WorkflowStepFiltersEditor;
