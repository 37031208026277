import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Background,
  Connection,
  ConnectionLineType,
  Controls,
  OnConnectEnd,
  OnConnectStart,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import WorkflowStepNode from './WorkflowStepNode';
import { useGetGlobalMediaConfigurationQuery, useGetMediaConfigurationQuery, useGetMediaConfigurationTypesQuery } from '@apis';
import { useAppSelector, useAppDispatch } from '@hooks';
import {
  updateTemplateStep,
  setResetFlag,
  removeEdge,
  addEdge,
  addDestinationStep,
  removeTemplateStep,
  openSnackbar,
  updateDestinationStep,
  removeDestinationStep,
  updateSaveStepLayout,
  clearPendingAction,
  setPendingAction,
  setUnsavedChanges,
  updateStepService,
} from '@slices';
import StepMediaConfigurationDialog from './StepMediaConfigurationDialog';
import StepSidePanel from './StepSidePanel';
import CustomEdge from './CustomEdge';
import EdgePropertiesModal from './EdgePropertiesModal';
import {
  DestinationStep,
  IconOption,
  PostOrPutWorkflowTemplateDto,
  WorkflowServiceDto,
  WorkflowStepDto,
  WorkflowStepEdgeItem,
  WorkflowStepNodeItem,
} from '@interfaces';
import { iconsList } from '@constants';
import { Spinner, UnsavedChangesDialog } from '@components';

type Props = {
  workflow: PostOrPutWorkflowTemplateDto | null;
  isLoading: boolean;
  addStep: (isService?: boolean) => WorkflowStepDto;
};

const nodeTypes = {
  custom: WorkflowStepNode,
};

const edgeTypes = {
  custom: CustomEdge,
};

const WorkflowFlow = ({ workflow, isLoading, addStep }: Props) => {
  const { t } = useTranslation('pano');
  const [nodes, setNodes, onNodesChange] = useNodesState<WorkflowStepNodeItem>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<WorkflowStepEdgeItem>([]);
  const [selectedStep, setSelectedStep] = useState<WorkflowStepDto | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEdgeModalOpen, setIsEdgeModalOpen] = useState(false);
  const [newEdge, setNewEdge] = useState<Connection | null>(null);
  const [selectedEdge, setSelectedEdge] = useState<WorkflowStepEdgeItem | null>(null);
  const { isReset, unsavedChanges } = useAppSelector((x) => x.mahon);
  const { isDarkMode, activeTeam } = useAppSelector((x) => x.app);
  const [showGlobalUnsavedDialog, setShowGlobalUnsavedDialog] = useState(false);
  const dispatch = useAppDispatch();
  const connectionStartRef = useRef<{ nodeId: string; handleId: string } | null>(null);

  const { refetch } = useGetGlobalMediaConfigurationQuery(activeTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
    skip: !activeTeam,
  });

  const refetchGlobalMedia = (): void => {
    refetch();
  };

  useGetMediaConfigurationQuery(activeTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
    skip: !activeTeam,
  });

  useGetMediaConfigurationTypesQuery(activeTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
    skip: !activeTeam,
  });

  useEffect(() => {
    if (workflow && isReset) {
      const nodeSpacingX = 320;
      const initialYOffset = 130;
      const nodeSpacingY = 50;

      const newNodes = workflow.steps.reduce((acc: WorkflowStepNodeItem[], step, index) => {
        const existingNode = nodes.find((node) => node.id === `${step.id}`);
        let position = existingNode ? existingNode.position : { x: nodeSpacingX * index + 20, y: initialYOffset + nodeSpacingY * index };

        while (acc.some((node) => node.position.x === position.x && node.position.y === position.y)) {
          position = { ...position, y: position.y + 200 };
        }

        const newNode: WorkflowStepNodeItem = step.isService
          ? {
              id: `${step.id}`,
              type: 'custom' as const,
              data: {
                label: step.name,
                index: step.stepIndex,
                onLabelChange: (newLabel: string, shouldDispatch: boolean = false) => handleStepLabelChange(step, newLabel, shouldDispatch),
                type: 'service',
                service: step.service as WorkflowServiceDto,
              },
              position,
            }
          : {
              id: `${step.id}`,
              type: 'custom' as const,
              data: {
                label: step.name,
                authorOnly: step.authorOnly,
                index: step.stepIndex,
                isFirst: index === 0,
                onLabelChange: (newLabel: string, shouldDispatch: boolean = false) => handleStepLabelChange(step, newLabel, shouldDispatch),
                onAuthorOnlyChange: (newValue: boolean) => handleAuthorOnlyChange(step, newValue),
                type: 'step',
              },
              position,
            };

        acc.push(newNode);
        return acc;
      }, []);

      newNodes.forEach((node) => {
        if (node.data.type === 'step') node.data.onMediaClick = () => handleMediaClick(node);
      });

      const newEdges = [] as WorkflowStepEdgeItem[];
      workflow.steps.forEach((step, index) => {
        if (step.isService && step.service && step.id > 0) {
          step.service.statusRoutes.forEach((route) => {
            if (route.progressStepIndex > -1) {
              const targetStep = workflow.steps.find((s) => s.stepIndex === route.progressStepIndex);
              if (targetStep) {
                newEdges.push({
                  id: `e${step.id}-${targetStep.id}-${route.name}-${Date.now()}`,
                  source: `${step.id}`,
                  target: `${targetStep.id}`,
                  sourceHandle: `${route.name}-${step.id}`,
                  type: 'custom' as const,
                  animated: false,
                  data: { label: '', icon: null, color: isDarkMode ? 'white' : 'black', marker: false },
                });
              }
            }
          });

          if (index > 0) {
            const prevStep = workflow.steps[index - 1];
            newEdges.push(createDefaultEdge(`${prevStep.id}`, `${step.id}`, isDarkMode));
          }
        } else {
          let hasDestinationSteps = false;
          let hasDestinationStepCount = 0;
          step.stepConfigurations.forEach((config) => {
            if (config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto' && config.mediaConfiguration.destinationSteps) {
              hasDestinationSteps = true;
              const relevantDestinationSteps = config.mediaConfiguration.destinationSteps.filter((ds) => ds.stepId === step.id);
              hasDestinationStepCount = relevantDestinationSteps.length;
              relevantDestinationSteps.forEach((destinationStep) => {
                const findStep = workflow.steps.find((x) => x.stepIndex === destinationStep.targetStepIndex);
                if (findStep && !edges.some((edge) => edge.source === `${step.id}` && edge.target === `${findStep.id}`)) {
                  const iconDetails = iconsList.find((x) => x.id == destinationStep.icon);
                  newEdges.push({
                    id: `e${step.id}-${findStep.id}-${Date.now()}`,
                    source: `${step.id}`,
                    target: `${findStep.id}`,
                    type: 'custom' as const,
                    animated: false,
                    data: { label: destinationStep.tooltip, icon: iconDetails, color: destinationStep.colour, marker: true },
                  });
                }
              });
            }
          });

          if (!hasDestinationSteps || hasDestinationStepCount === 0) {
            if (index > 0) {
              const prevStep = workflow.steps[index - 1];
              const prevStepId = `${prevStep.id}`;
              const currentStepId = `${step.id}`;
              if (!edgeExists(prevStepId, currentStepId, edges, newEdges)) {
                newEdges.push(createDefaultEdge(prevStepId, currentStepId, isDarkMode));
              }
            }

            if (index < workflow.steps.length - 1) {
              const nextStep = workflow.steps[index + 1];
              const nextStepId = `${nextStep.id}`;
              const currentStepId = `${step.id}`;
              if (!edgeExists(currentStepId, nextStepId, edges, newEdges) && !nextStep.isService) {
                newEdges.push(createDefaultEdge(currentStepId, nextStepId, isDarkMode));
              }
            }
          }
        }
      });

      setNodes(newNodes);
      setEdges((eds) => eds.concat(newEdges));
    }
    if (isReset) {
      dispatch(setResetFlag(false));
    }
  }, [workflow, isReset]);

  const edgeExists = (sourceId: string, targetId: string, edges: WorkflowStepEdgeItem[], newEdges: WorkflowStepEdgeItem[]): boolean => {
    return (
      edges.some((edge) => (edge.source === sourceId && edge.target === targetId) || (edge.source === targetId && edge.target === sourceId)) ||
      newEdges.some((edge) => (edge.source === sourceId && edge.target === targetId) || (edge.source === targetId && edge.target === sourceId))
    );
  };

  const createDefaultEdge = (sourceId: string, targetId: string, isDarkMode: boolean): WorkflowStepEdgeItem => {
    return {
      id: `e${sourceId}-${targetId}-${Date.now()}`,
      source: sourceId,
      target: targetId,
      type: 'custom' as const,
      animated: true,
      data: { label: '', icon: null, color: isDarkMode ? 'white' : 'black', marker: false },
    };
  };

  const [_selectedNodeId, setSelectedNodeId] = useState<string | null>(null);

  const handleNodeClick = (node: WorkflowStepNodeItem) => {
    if (node.data.type === 'service') return;
    if (unsavedChanges.hasUnsavedChanges) {
      setShowGlobalUnsavedDialog(true);
      dispatch(setPendingAction({ type: 'selectNode', payload: node }));
    } else {
      selectNode(node.id);
    }
  };

  const selectNode = useCallback(
    (nodeId: string) => {
      setSelectedNodeId((prevSelectedNodeId) => {
        if (prevSelectedNodeId === nodeId) {
          setSelectedStep(null);
          setIsDrawerOpen(false);
          return null;
        } else {
          const step = workflow?.steps.find((s) => s.id === parseInt(nodeId));
          setSelectedStep(step ?? null);
          setIsDrawerOpen(true);
          return nodeId;
        }
      });
    },
    [workflow],
  );

  const handleClosePanel = useCallback(() => {
    if (unsavedChanges.hasUnsavedChanges) {
      setShowGlobalUnsavedDialog(true);
      dispatch(setPendingAction({ type: 'close' }));
    } else {
      setIsDrawerOpen(false);
      setSelectedStep(null);
      setSelectedNodeId(null);
    }
  }, [unsavedChanges.hasUnsavedChanges, dispatch]);

  const handleGlobalSaveChanges = useCallback(() => {
    dispatch(updateSaveStepLayout(true));
    setTimeout(() => {
      dispatch(setUnsavedChanges(false));
      if (unsavedChanges.pendingAction) {
        switch (unsavedChanges.pendingAction.type) {
          case 'selectNode':
            selectNode(unsavedChanges.pendingAction.payload.id);
            break;
          case 'close':
            setIsDrawerOpen(false);
            setSelectedStep(null);
            setSelectedNodeId(null);
            break;
        }
      }
      setShowGlobalUnsavedDialog(false);
      dispatch(clearPendingAction());
    }, 0);
  }, [dispatch, unsavedChanges.pendingAction, selectNode]);

  const handleGlobalDiscardChanges = useCallback(() => {
    dispatch(setUnsavedChanges(false));
    if (unsavedChanges.pendingAction) {
      switch (unsavedChanges.pendingAction.type) {
        case 'selectNode':
          selectNode(unsavedChanges.pendingAction.payload.id);
          break;
        case 'close':
          setIsDrawerOpen(false);
          setSelectedStep(null);
          setSelectedNodeId(null);
          break;
      }
    }
    setShowGlobalUnsavedDialog(false);
    dispatch(clearPendingAction());
  }, [dispatch, unsavedChanges.pendingAction, selectNode]);

  const handleMediaClick = (node: WorkflowStepNodeItem) => {
    if (workflow?.steps) {
      setSelectedStep(workflow?.steps.find((x) => x.id === parseInt(node.id)) ?? null);
      setIsDialogOpen(true);
    }
  };

  const handleStepLabelChange = (step: WorkflowStepDto, newLabel: string, shouldDispatch: boolean) => {
    if (shouldDispatch) {
      const updatedStep = { ...step, name: newLabel };
      dispatch(updateTemplateStep(updatedStep));
    } else {
      setNodes((nds) =>
        nds.map((node) =>
          node.id === `${step.id}`
            ? {
                ...node,
                data: {
                  ...node.data,
                  label: newLabel,
                },
              }
            : node,
        ),
      );
    }
  };

  const handleAuthorOnlyChange = (step: WorkflowStepDto, newValue: boolean) => {
    const updatedStep = { ...step, authorOnly: newValue };
    dispatch(updateTemplateStep(updatedStep));
  };

  const onConnect = (params: Connection) => {
    const sourceStep = workflow?.steps.find((x) => x.id === parseInt(params.source ?? '0'));
    if (sourceStep?.isService) {
      const statusRouteName = params.sourceHandle?.split('-')[0];

      if (statusRouteName) {
        setEdges((eds) => eds.filter((edge) => !(edge.source === params.source && edge.sourceHandle?.startsWith(statusRouteName))));

        const updatedEdge: WorkflowStepEdgeItem = {
          id: `e${params.source}-${params.target}-${statusRouteName}-${Date.now()}`,
          type: 'custom' as const,
          data: {
            label: '',
            icon: null,
            color: isDarkMode ? 'white' : 'black',
            marker: false,
          },
          source: params.source as string,
          target: params.target as string,
          sourceHandle: params.sourceHandle,
          targetHandle: params.targetHandle,
        };

        setEdges((eds) => eds.concat(updatedEdge));
        dispatch(addEdge(updatedEdge));

        const targetStep = workflow?.steps.find((x) => x.id === parseInt(params.target ?? '0'));
        if (sourceStep.service && targetStep) {
          const updatedStatusRoutes = sourceStep.service.statusRoutes.map((route) =>
            route.name === statusRouteName ? { ...route, progressStepIndex: targetStep.stepIndex } : route,
          );

          dispatch(
            updateStepService({
              service: { ...sourceStep.service, statusRoutes: updatedStatusRoutes },
              stepId: sourceStep.id,
            }),
          );
        }
      }
    } else {
      if (sourceStep?.stepConfigurations.find((z) => z.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto')) {
        setNewEdge(params);
        setSelectedEdge(null);
        setIsEdgeModalOpen(true);
      } else {
        dispatch(openSnackbar({ message: t('stepNeedsFlowControlMedia'), severity: 'info', display: true }));
      }
    }
  };

  const handleSaveEdgeProperties = (properties: { color: string; icon: IconOption; tooltip: string }) => {
    if (newEdge) {
      const updatedEdge: WorkflowStepEdgeItem = {
        id: `e${newEdge.source}-${newEdge.target}-${Date.now()}`,
        type: 'custom' as const,
        data: { label: properties.tooltip, icon: properties.icon, color: properties.color, marker: true },
        source: newEdge.source as string,
        target: newEdge.target as string,
        sourceHandle: newEdge.sourceHandle ?? null,
        targetHandle: newEdge.targetHandle ?? null,
      };
      const findEdge = edges.find((edge) => !(edge.source === newEdge.source && edge.target === newEdge.target));
      dispatch(removeEdge(findEdge?.id as string));
      setEdges((eds) => eds.filter((edge) => !(edge.source === newEdge.source && edge.target === newEdge.target)));

      setEdges((eds) => eds.concat(updatedEdge));
      dispatch(addEdge(updatedEdge));

      const sourceStepId = parseInt(newEdge.source as string);
      const destinationStep: DestinationStep = {
        colour: properties.color,
        icon: properties.icon.id,
        stepId: sourceStepId,
        targetStepIndex: workflow?.steps.find((step) => step.id === parseInt(newEdge.target as string))?.stepIndex ?? 0,
        tooltip: properties.tooltip,
      };

      dispatch(addDestinationStep(destinationStep));

      setNewEdge(null);
      setIsEdgeModalOpen(false);
    } else if (selectedEdge) {
      const findEdge = edges.find((edge) => edge.id === selectedEdge.id);
      if (findEdge) {
        const updatedEdge: WorkflowStepEdgeItem = {
          ...selectedEdge,
          data: {
            marker: true,
            label: properties.tooltip,
            icon: properties.icon,
            color: properties.color,
          },
        };

        setEdges((eds) => eds.map((edge) => (edge.id === selectedEdge.id ? updatedEdge : edge)));

        const sourceStepId = parseInt(selectedEdge.source as string);
        const destinationStep: DestinationStep = {
          colour: properties.color,
          icon: properties.icon.id,
          stepId: sourceStepId,
          targetStepIndex: workflow?.steps.find((step) => step.id === parseInt(selectedEdge.target as string))?.stepIndex ?? 0,
          tooltip: properties.tooltip,
        };

        dispatch(updateDestinationStep(destinationStep));

        setIsEdgeModalOpen(false);
        setSelectedEdge(null);
      }
    }
  };

  const handleRemoveDestinationStep = (): void => {
    if (selectedEdge) {
      const updatedEdges = edges.filter((edge) => !(edge.source === selectedEdge.source && edge.target === selectedEdge.target));
      setEdges(updatedEdges);

      dispatch(
        removeDestinationStep({
          stepId: parseInt(selectedEdge.source as string),
          targetStepIndex: workflow?.steps.find((step) => step.id === parseInt(selectedEdge.target as string))?.stepIndex ?? 0,
        }),
      );

      setIsEdgeModalOpen(false);
      setSelectedEdge(null);
    }
  };

  const onEdgeClick = (_: React.MouseEvent, edge: WorkflowStepEdgeItem) => {
    if (edge.data?.marker) {
      setSelectedEdge(edge);
      setIsEdgeModalOpen(true);
    }
  };

  const onNodesDelete = useCallback(
    (deleted: WorkflowStepNodeItem[]) => {
      deleted.forEach((element) => {
        dispatch(removeTemplateStep(parseInt(element.id)));
      });
    },
    [nodes, edges],
  );

  const handleEdgeDelete = (edgeId: string) => {
    dispatch(removeEdge(edgeId));
  };

  const onConnectStart: OnConnectStart = (_event, { nodeId, handleId }) => {
    if (nodeId && handleId) connectionStartRef.current = { nodeId, handleId };
  };

  const onConnectEnd: OnConnectEnd = useCallback(
    (event) => {
      const targetIsPane = (event.target as Element).classList.contains('react-flow__pane');
      if (targetIsPane && connectionStartRef.current) {
        const { nodeId: sourceNodeId, handleId: sourceHandle } = connectionStartRef.current;

        const sourceNode = nodes.find((n) => n.id === sourceNodeId);
        if (sourceNode && sourceNode.data.type === 'service') {
          const newStep = addStep(false);
          const statusRouteName = sourceHandle?.split('-')[0];

          if (statusRouteName && newStep) {
            setEdges((eds) => eds.filter((edge) => !(edge.source === sourceNodeId && edge.sourceHandle?.startsWith(statusRouteName))));

            const updatedEdge = {
              id: `e${sourceNodeId}-${newStep.id}-${statusRouteName}-${Date.now()}`,
              type: 'custom' as const,
              data: {
                label: statusRouteName,
                icon: null,
                color: isDarkMode ? 'white' : 'black',
                marker: false,
              },
              source: sourceNodeId,
              target: newStep.id.toString(),
              sourceHandle: sourceHandle,
            };

            setEdges((eds) => eds.concat(updatedEdge));
            dispatch(addEdge(updatedEdge));

            if (sourceNode.data.service) {
              const updatedStatusRoutes = sourceNode.data.service.statusRoutes.map((route) =>
                route.name === statusRouteName ? { ...route, progressStepIndex: newStep.stepIndex } : route,
              );

              dispatch(
                updateStepService({
                  service: { ...sourceNode.data.service, statusRoutes: updatedStatusRoutes },
                  stepId: parseInt(sourceNode.id),
                }),
              );
            }
          }
        }
      }
    },
    [nodes, workflow],
  );

  return (
    <Paper className="w-full">
      <Grid container style={{ height: 'calc(100vh - 315px)' }}>
        <Grid item xs={isDrawerOpen ? 9 : 12}>
          {isLoading ? (
            <Spinner />
          ) : (
            <ReactFlowProvider>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                onNodeClick={(_, node) => handleNodeClick(node)}
                onNodesDelete={onNodesDelete}
                onEdgeClick={onEdgeClick}
                onConnect={onConnect}
                onEdgesDelete={(edges) => edges.forEach((edge) => handleEdgeDelete(edge.id))}
                connectionLineType={ConnectionLineType.SmoothStep}
                proOptions={{ hideAttribution: true }}
                onConnectEnd={onConnectEnd}
                onConnectStart={onConnectStart}
                colorMode={isDarkMode ? 'dark' : 'light'}>
                <Controls />
                <Background />
              </ReactFlow>
            </ReactFlowProvider>
          )}
        </Grid>
        {isDrawerOpen && (
          <Grid item xs={3}>
            {selectedStep && (
              <StepSidePanel
                onClose={handleClosePanel}
                step={selectedStep}
                isLoading={isLoading}
                open={isDrawerOpen}
                onSaveChanges={handleGlobalSaveChanges}
                onDiscardChanges={handleGlobalDiscardChanges}
              />
            )}
          </Grid>
        )}
        {selectedStep && (
          <StepMediaConfigurationDialog
            refetchGlobalMedia={refetchGlobalMedia}
            step={selectedStep}
            isLoading={isLoading}
            isDialogOpen={isDialogOpen}
            setIsDialogOpen={setIsDialogOpen}
          />
        )}
        <EdgePropertiesModal
          onRemove={handleRemoveDestinationStep}
          isOpen={isEdgeModalOpen}
          edge={selectedEdge}
          onClose={() => setIsEdgeModalOpen(false)}
          onSave={handleSaveEdgeProperties}
        />
      </Grid>
      <UnsavedChangesDialog
        open={showGlobalUnsavedDialog}
        onClose={() => setShowGlobalUnsavedDialog(false)}
        onSave={handleGlobalSaveChanges}
        onDiscard={handleGlobalDiscardChanges}
      />
    </Paper>
  );
};

export default WorkflowFlow;
