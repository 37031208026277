import { useDisableMfaMutation, useUpdateUserMutation, useUserForceResetPasswordMutation } from '@apis';
import { useAppDispatch, useAppSelector, useForm } from '@hooks';
import { CurrentUserItem, UserForm, UserWorkerItem } from '@interfaces';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { logOut, openSnackbar, setNavigationActions } from '@slices';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RandomizePasswordButton from './RandomizePasswordButton';
import { PasswordDialog, TokenFormatListButton } from '@components';
import { userManager } from '@utils';
import { faKey, faShield } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { RoutePath, white } from '@constants';

type Props = {
  editUserData: CurrentUserItem | UserWorkerItem;
  userId: number;
  isMyAccountPage: boolean;
};

const UserEditForm = ({ editUserData, userId, isMyAccountPage }: Props): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userValidation } = useAppSelector((x) => x.user);
  const { sideMenuDrawOpenStatus } = useAppSelector((x) => x.app);
  const titleOptions = [
    t('TitleLabelDame'),
    t('TitleLabelDr'),
    t('TitleLabelMiss'),
    t('TitleLabelMr'),
    t('TitleLabelMrs'),
    t('TitleLabelMs'),
    t('TitleLabelMx'),
    t('TitleLabelProf'),
    t('TitleLabelSir'),
  ];
  const [updateUser, { isLoading: isUpdatingUser }] = useUpdateUserMutation();
  const [disableMfa, { isLoading: isDisablingMfa }] = useDisableMfaMutation();

  const [userForceResetPassword, { isLoading: isForceResetPassword }] = useUserForceResetPasswordMutation();
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false);
  const [isPasswordDialogOpen, setPasswordIsDialogOpen] = useState<boolean>(false);

  const handleUserSubmit = (): void => {
    updateUser({ newData: formData, userId: isMyAccountPage ? undefined : userId })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to update user', err);
        dispatch(openSnackbar({ message: t('failCheckDetails'), severity: 'error', display: true }));
        handleReset();
        return;
      });
  };

  const forceResetPassword = (): void => {
    userForceResetPassword(userId)
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to force reset password', err);
        return;
      });
  };

  const { handleSubmit, handleChange, formData, setFormData, errors, hasFormChanged, setHasFormChanged } = useForm<UserForm>({
    validations: {
      email: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
        pattern: { 
          value: /^[a-zA-Z0-9._%+-]*[a-z\d']+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
          message: t('invalidEmail') },
      },
      givenName: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
      familyName: {
        required: {
          value: true,
          message: t('fieldIsRequired'),
        },
      },
    },
    onSubmit: handleUserSubmit,
    initialValues: {
      email: editUserData.email,
      givenName: editUserData.givenName,
      familyName: editUserData.familyName,
      title: editUserData.title ?? '',
    },
  });

  const handleReset = (): void => {
    setFormData({
      email: editUserData.email,
      givenName: editUserData.givenName,
      familyName: editUserData.familyName,
      title: editUserData.title ?? '',
    });
    setHasFormChanged(false);
  };

  const closeLogoutDialog = () => {
    setIsLogoutDialogOpen(false);
    dispatch(logOut());
    userManager.signoutRedirect();
  };

  const userNavigationActions = (mfaEnabled: boolean): JSX.Element => {
    return (
      <List>
        {sideMenuDrawOpenStatus && (
          <ListSubheader inset={true}>
            <Typography component="span" variant="h6" color={white[500]}>
              {t('actions')}
            </Typography>
          </ListSubheader>
        )}

        <TokenFormatListButton />

        <Tooltip title={t('changePasswordTooltip')}>
          <ListItemButton onClick={() => navigate(RoutePath.UserChangePasswordPath)}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faKey} size="lg" fixedWidth style={{ color: white[500] }} />
            </ListItemIcon>
            <ListItemText primary={t('changePasswordHeader')}></ListItemText>
          </ListItemButton>
        </Tooltip>
        {mfaEnabled ? (
          <Tooltip title={t('mfaDisableToolTip')}>
            <ListItemButton onClick={validatePasswordToken} disabled={isDisablingMfa}>
              <ListItemIcon>
                <FontAwesomeIcon icon={faShield} size="lg" fixedWidth style={{ color: white[500] }} />
              </ListItemIcon>
              <ListItemText primary={t('mfaDisable')}></ListItemText>
            </ListItemButton>
          </Tooltip>
        ) : (
          <React.Fragment />
        )}
      </List>
    );
  };

  const handleDisableMfa = (value?: string): void => {
    disableMfa(value as string)
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('mfaDisabled'), severity: 'success', display: true }));
        setIsLogoutDialogOpen(true);
      })
      .catch((err) => {
        console.debug('Failed while attempting to disable MFA', err);
        return;
      });
  };

  const validatePasswordToken = (): void => {
    let dateNow = new Date();
    let dateToken = userValidation ? new Date(userValidation.validUntil) : null;

    if (!dateToken || dateNow > dateToken) {
      setPasswordIsDialogOpen(true);
    } else {
      handleDisableMfa(userValidation?.token);
    }
  };

  useEffect(() => {
    if (editUserData && 'mfaEnabled' in editUserData) {
      dispatch(setNavigationActions(userNavigationActions(editUserData.mfaEnabled)));
    }
  }, [editUserData, sideMenuDrawOpenStatus]);

  return (
    <Grid container>
      <form onSubmit={handleSubmit} className="w-full">
        <Grid item xs={12} sm={12} md={12} lg={12} className="space-y-6">
          <Typography variant="h6">{t('personalInfo')}</Typography>

          <TextField
            name="email"
            autoComplete="email"
            label={t('emailAddress')}
            placeholder={t('emailAddress') as string}
            fullWidth
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            disabled={isUpdatingUser}
            {...(errors?.email && { error: true, helperText: errors.email })}
          />

          <Autocomplete
            freeSolo
            autoSelect
            options={titleOptions}
            disabled={isUpdatingUser}
            onChange={(event, newValue) => handleChange(event, false, newValue as string, 'title')}
            value={formData.title}
            renderInput={(params) => <TextField {...params} label={t('title')} variant="outlined" fullWidth />}
          />

          <TextField
            name="givenName"
            autoComplete="givenName"
            label={t('givenName')}
            placeholder={t('givenName') as string}
            fullWidth
            value={formData.givenName}
            onChange={handleChange}
            variant="outlined"
            disabled={isUpdatingUser}
            {...(errors?.givenName && { error: true, helperText: errors.givenName })}
          />

          <TextField
            name="familyName"
            autoComplete="familyName"
            label={t('familyName')}
            placeholder={t('familyName') as string}
            fullWidth
            value={formData.familyName}
            onChange={handleChange}
            variant="outlined"
            disabled={isUpdatingUser}
            {...(errors?.familyName && { error: true, helperText: errors.familyName })}
          />
        </Grid>
        <Grid container item xs={12} sm={12} md={12} lg={12} className="pt-4">
          {editUserData ? (
            <Grid item xs={12} sm={12} md={4} lg={4} className="padding-area-bottom">
              <div className="align-left">
                {!isMyAccountPage ? (
                  <div className="w-32">
                    <RandomizePasswordButton userId={userId} />
                    <Button className="!mt-4" color="info" variant="contained" disabled={isForceResetPassword} onClick={forceResetPassword}>
                      {isForceResetPassword && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                      {t('forceResetPassword')}
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </Grid>
          ) : (
            <React.Fragment />
          )}

          <Grid container item xs={12} sm={12} md={8} lg={8} className="justify-end pt-5 h-12">
            <Button className="!mr-5" variant="contained" color="secondary" onClick={handleReset} disabled={!hasFormChanged || isUpdatingUser}>
              {t('clearButtonText')}
            </Button>
            <Button variant="contained" color="primary" type="submit" disabled={!hasFormChanged || isUpdatingUser}>
              {isUpdatingUser && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
              {t('submitButtonText')}
            </Button>
          </Grid>
        </Grid>
      </form>

      <Dialog open={isLogoutDialogOpen} onClose={closeLogoutDialog} className="dialog-content" fullWidth disableEscapeKeyDown>
        <DialogTitle>{t('mfaLogoutTitle')}</DialogTitle>

        <DialogContent>
          <p>{t('mfaLogoutBody1')}</p>
          <p>{t('mfaLogoutBody2')}</p>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" color="primary" onClick={closeLogoutDialog}>
            {t('continue')}
          </Button>
        </DialogActions>
      </Dialog>

      <PasswordDialog setOpen={setPasswordIsDialogOpen} open={isPasswordDialogOpen} onSuccessAction={handleDisableMfa} />
    </Grid>
  );
};

export default UserEditForm;
