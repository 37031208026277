import React, { useEffect, useState, createRef } from 'react';
import { useAppDispatch } from '@hooks';
import { SystemMessageConfig, WorkflowServiceDto } from '@interfaces';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, CircularProgress, TextField } from '@mui/material';
import { updateStepService } from '@slices';
import { useTranslation } from 'react-i18next';
import MaterialTable from '@material-table/core';
import { TableIcons } from '@components';
import { openSnackbar } from '@slices';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';

interface ServiceConfigurationDialogProps {
  service: WorkflowServiceDto;
  isLoading: boolean;
  stepId: number;
  isServiceDialogOpen: boolean;
  isConfigurable: boolean;
  setSerivceDialogIsOpen: (isOpen: boolean) => void;
}

interface TableData {
  id: number;
  type: string;
  key: string;
  value: string;
}

const ServiceConfigurationDialog: React.FC<ServiceConfigurationDialogProps> = ({
  service,
  stepId,
  isServiceDialogOpen,
  isLoading,
  setSerivceDialogIsOpen,
  isConfigurable,
}) => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [summary, setSummary] = useState<string>('');
  const [nextId, setNextId] = useState<number>(0);
  const tableRef = createRef() as React.RefObject<any>;
  const [copiedText, copy] = useCopyToClipboard();
  useEffect(() => {
    if (service && service.config) {
      if (service.config.systemMessageTypes?.length > 0) {
        const configTableData = service.config.systemMessageTypes.flatMap((type, typeIndex) =>
          type.systemMessages.map((msg, msgIndex) => ({
            id: typeIndex * 1000 + msgIndex,
            type: type.typeName,
            key: msg.key,
            value: msg.value,
          })),
        );
        setTableData(configTableData);
        setNextId(Math.max(...configTableData.map((row) => row.id)) + 1);
      } else {
        setTableData([]);
        setNextId(0);
      }
      setSummary(service.config.systemMessageSummary || '');
    }
  }, [service, isServiceDialogOpen]);

  const validateData = (data: TableData) => {
    if (!data.type?.trim()) {
      dispatch(openSnackbar({ message: t('typeRequired'), severity: 'error', display: true }));
      return false;
    }
    if (!data.key?.trim()) {
      dispatch(openSnackbar({ message: t('keyRequired'), severity: 'error', display: true }));
      return false;
    }
    if (!data.value?.trim()) {
      dispatch(openSnackbar({ message: t('valueRequired'), severity: 'error', display: true }));
      return false;
    }
    return true;
  };

  const handleRowAdd = (newData: TableData) =>
    new Promise<void>((resolve, reject) => {
      try {
        if (!validateData(newData)) {
          reject();
          return;
        }
        const dataToAdd = { ...newData, id: nextId };
        setTableData([...tableData, dataToAdd]);
        setNextId(nextId + 1);
        resolve();
      } catch (error) {
        reject();
      }
    });

  const handleRowUpdate = (newData: TableData, oldData?: TableData) =>
    new Promise<void>((resolve, reject) => {
      try {
        if (!validateData(newData)) {
          reject();
          return;
        }
        if (oldData) {
          const dataUpdate = [...tableData];
          const index = dataUpdate.findIndex((item) => item.id === oldData.id);
          if (index !== -1) {
            dataUpdate[index] = { ...newData, id: oldData.id };
            setTableData(dataUpdate);
          }
        }
        resolve();
      } catch (error) {
        reject();
      }
    });

  const handleRowDelete = (oldData: TableData) =>
    new Promise<void>((resolve, reject) => {
      try {
        const dataDelete = [...tableData];
        const index = dataDelete.findIndex((item) => item.id === oldData.id);
        if (index !== -1) {
          dataDelete.splice(index, 1);
          setTableData(dataDelete);
        }
        resolve();
      } catch (error) {
        reject();
      }
    });

  const handleSaveConfig = () => {
    for (const row of tableData) {
      if (!validateData(row)) {
        return;
      }
    }

    const messageTypeMap = new Map<string, { key: string; value: string }[]>();

    tableData.forEach((row) => {
      if (!messageTypeMap.has(row.type)) {
        messageTypeMap.set(row.type, []);
      }
      messageTypeMap.get(row.type)?.push({
        key: row.key,
        value: row.value,
      });
    });

    const newConfig: SystemMessageConfig = {
      systemMessageTypes: Array.from(messageTypeMap.entries()).map(([typeName, messages]) => ({
        typeName: typeName,
        systemMessages: messages,
      })),
      systemMessageSummary: summary,
    };

    const updatedService = {
      ...service,
      config: newConfig,
    };

    dispatch(
      updateStepService({
        service: updatedService,
        stepId: stepId,
      }),
    );
    setSerivceDialogIsOpen(false);
  };

  const renderUnconfigurableMessage = () => (
    <div className="space-y-2">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} className="text-center">
          <Typography variant="h5">{t('serviceDoesNotHaveConfiguration')}</Typography>
        </Grid>
      </Grid>
    </div>
  );

  const renderConfigurationTable = () => (
    <div className="space-y-4">
      <TextField
        label={t('systemMessageSummary')}
        multiline
        rows={2}
        value={summary}
        onChange={(e) => setSummary(e.target.value)}
        fullWidth
        variant="outlined"
        margin="normal"
      />
      <MaterialTable
        icons={TableIcons}
        tableRef={tableRef}
        title={t('serviceConfigTitle')}
        columns={[
          {
            title: t('WorkflowServiceType'),
            field: 'type',
            lookup: {
              Text: 'Text',
              Email: 'Email',
              Actions: 'Actions',
              CalendarEntry: 'Calendar Entry',
              ThirdParty: 'Third Party',
            },
            validate: (rowData) => (!rowData.type ? { isValid: false, helperText: t('WorkflowServiceTypeRequired') as string } : true),
          },
          {
            title: t('WorkflowServiceTitle'),
            field: 'key',
            validate: (rowData) => (!rowData.key ? { isValid: false, helperText: t('WorkflowServiceTitleRequired') as string } : true),
          },
          {
            title: t('WorkflowServiceValue'),
            field: 'value',
            validate: (rowData) => (!rowData.value ? { isValid: false, helperText: t('WorkflowServiceValueRequired') as string } : true),
          },
        ]}
        data={tableData}
        editable={{
          onRowAdd: handleRowAdd,
          onRowUpdate: handleRowUpdate,
          onRowDelete: handleRowDelete,
        }}
        options={{
          actionsCellStyle: { padding: '0px 20px', textAlign: 'center' },
          actionsColumnIndex: -1,
          addRowPosition: 'first',
          sorting: true,
          search: true,
        }}
      />
    </div>
  );

  const handleCloseDialog = () => {
    setSerivceDialogIsOpen(false);
  };
  const handleCopySystemMessageCopy= () =>{
    const configAsString = JSON.stringify(service.config);
    copy(configAsString);    
    console.log("System Message Copied"); 
    console.log(copiedText);  
  }

  return (
    <Dialog open={isServiceDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="md">
      <DialogTitle>{t('editWorkflowServiceConfig')}</DialogTitle>
      <DialogContent>{isConfigurable ? renderConfigurationTable() : renderUnconfigurableMessage()}</DialogContent>
      <DialogActions>
      <Button variant="contained" color="primary" onClick={handleCopySystemMessageCopy} disabled={isLoading}>
          {t('WorkflowServiceCopyMessage')}
      </Button>
      <Button variant="contained" color="secondary" onClick={handleCloseDialog} disabled={isLoading}>
          {t('cancelButtonText')}
      </Button>
      {isConfigurable && (
        <Button variant="contained" color="primary" onClick={handleSaveConfig} disabled={isLoading}>
          {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
          {t('submitButtonText')}
        </Button>
      )}       
    </DialogActions>
    </Dialog>
  );
};

export default ServiceConfigurationDialog;
