import {
  useGetStepLayoutCommandMutation,
  useGetWorflowServiceTemplatesQuery,
  useGetWorkflowLayoutsQuery,
  useGetWorkFlowTemplatesQuery,
  usePostWorkflowTemplateMutation,
  usePutWorkflowTemplateMutation,
} from '@apis';
import { WorkflowFlow, WorkflowTable } from '@components';
import { RoutePath } from '@constants';
import { useAppDispatch, useAppSelector } from '@hooks';
import { PostOrPutWorkflowTemplateDto, PublishAction, WorkflowServiceDto, WorkflowStepDto, WorkflowTemplateDto } from '@interfaces';
import { Autocomplete, Button, Chip, CircularProgress, FormControlLabel, FormGroup, Grid, MenuItem, Switch, TextField } from '@mui/material';
import {
  addOrUpdateStepLayout,
  addTemplateStep,
  initializeTemplate,
  openSnackbar,
  resetTemplate,
  setBreadcrumbs,
  setHelmet,
  setResetFlag,
  updateCurrentTemplateLabels,
  updateTemplateName,
  updateTemplatePublishAction,
} from '@slices';
import { encodeAndCompress } from '@utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

const WorkflowTemplate = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const parms = useParams();
  const templateId = parms?.templateId ? parseInt(parms?.templateId) : 0;
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState<boolean>(!templateId);
  const [workflowItem, setWorkflowItem] = useState<WorkflowTemplateDto>();
  const { currentTemplate, workflowTemplates, workflowStepNewId, workflowServiceNewId, workflowServiceTemplates } = useAppSelector((x) => x.mahon);
  const { activeTeam } = useAppSelector((x) => x.app);

  const [postWorkflowTemplate, { isLoading: postIsLoading }] = usePostWorkflowTemplateMutation();
  const [putWorkflowTemplate, { isLoading: putIsLoading }] = usePutWorkflowTemplateMutation();
  const [getStepLayout, { isLoading: getStepLayoutLoading }] = useGetStepLayoutCommandMutation();
  const [actualWorkflowId, setActualWorkflowId] = useState<number>(0);
  const { isLoading, isSuccess, refetch } = useGetWorkFlowTemplatesQuery(activeTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
    skip: templateId === 0 && !activeTeam,
  });

  const { isSuccess: isWorkflowLayoutsSuccess } = useGetWorkflowLayoutsQuery(
    { teamId: activeTeam?.id ?? 1, workflowId: actualWorkflowId },
    {
      refetchOnMountOrArgChange: true,
      skip: templateId === 0 || actualWorkflowId === 0,
    },
  );

  useGetWorflowServiceTemplatesQuery(activeTeam?.id ?? 1, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (templateId === 0) {
      dispatch(initializeTemplate());
      dispatch(setHelmet({ title: t('htmlTitleWorkflow') }));
      dispatch(setBreadcrumbs([{ text: t('htmlTitleWorkflow'), link: RoutePath.WorkflowTemplatesPath }, { text: t('newWorkflowTemplate') }]));
    }

    if (isSuccess) {
      const findWorkflow = workflowTemplates.find((x) => x.id === templateId);
      if (findWorkflow) {
        if (findWorkflow.workflowDto.workflowTemplateState === 1) setIsEditMode(false);
        setWorkflowItem(findWorkflow);
        setActualWorkflowId(findWorkflow.workflowDto.id);
        dispatch(setBreadcrumbs([{ text: t('htmlTitleWorkflow'), link: RoutePath.WorkflowTemplatesPath }, { text: findWorkflow.workflowDto.name }]));
      }
      dispatch(setHelmet({ title: t('htmlTitleWorkflow') }));
    }
  }, [isSuccess, workflowTemplates, templateId]);

  useEffect(() => {
    if (workflowItem && isSuccess && isWorkflowLayoutsSuccess) {
      dispatch(initializeTemplate(workflowItem));
    }
  }, [workflowItem, isSuccess, isWorkflowLayoutsSuccess]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTemplateName(event.target.value));
  };

  const handleLabelsChange = (_event: React.ChangeEvent<{}>, value: string[]) => {
    dispatch(updateCurrentTemplateLabels(value));
  };

  const handlePublishActionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateTemplatePublishAction(parseInt(event.target.value)));
  };

  const handleAddStep = (isService = false): WorkflowStepDto => {
    const newStep = {
      id: workflowStepNewId,
      name: isService ? 'New Service' : 'New Step',
      authorOnly: false,
      stepConfigurations: [],
      stepIndex: currentTemplate?.steps ? currentTemplate?.steps?.length + 1 : 1,
      workflowId: templateId === 0 ? -1 : currentTemplate?.id || 0,
      version: templateId === 0 ? -1 : 1,
      isService,
      workflowServiceId: null,
      service: isService
        ? ({
            id: workflowServiceNewId,
            workflowId: templateId === 0 ? -1 : currentTemplate?.id || 0,
            version: 1,
            workFlowServiceTemplateId: workflowServiceTemplates[0].id,
            statusRoutes: workflowServiceTemplates[0].statuses.map((item) => {
              let copyItem = { ...item };
              if (copyItem.progressStepIndex > 0) copyItem.progressStepIndex = 0;
              return copyItem;
            }),
            config: workflowServiceTemplates[0].config ? { ...workflowServiceTemplates[0].config } : { systemMessageTypes: [] },
          } as WorkflowServiceDto)
        : null,
    };
    dispatch(addTemplateStep(newStep));
    dispatch(
      addOrUpdateStepLayout({
        stepId: newStep.id,
        layoutType: 'DataView',
        description: '<EmptyLayout>',
        xml: encodeAndCompress(''),
      }),
    );
    return newStep;
  };

  const handleReset = () => {
    dispatch(resetTemplate(workflowItem));
  };

  const handleSubmit = async (): Promise<void> => {
    if (currentTemplate) {
      if (!currentTemplate.name.trim()) {
        dispatch(openSnackbar({ message: t('errorTemplateNameRequired'), severity: 'error', display: true }));
        return;
      }

      const isNameUnique = workflowTemplates.every(
        (template) => template.id === currentTemplate.id || template.workflowDto.name.toLowerCase() !== currentTemplate.name.toLowerCase(),
      );
      if (!isNameUnique) {
        dispatch(openSnackbar({ message: t('errorTemplateNameUnique'), severity: 'error', display: true }));
        return;
      }

      if (currentTemplate.steps.length === 0) {
        dispatch(openSnackbar({ message: t('errorAtLeastOneStep'), severity: 'error', display: true }));
        return;
      }

      const stepNames = new Set();
      for (const step of currentTemplate.steps) {
        if (!step.name.trim()) {
          dispatch(openSnackbar({ message: t('errorAllStepsNeedName'), severity: 'error', display: true }));
          return;
        }
        if (stepNames.has(step.name)) {
          dispatch(openSnackbar({ message: t('errorUniqueStepNames'), severity: 'error', display: true }));
          return;
        }
        stepNames.add(step.name);

        if (step.isService && step.service) {
          const invalidStatusRoutes = step.service.statusRoutes.some((route) => route.progressStepIndex !== -1 && route.progressStepIndex === 0);
          if (invalidStatusRoutes) {
            dispatch(
              openSnackbar({
                message: t('errorStatusRouteNotConnectedToStep'),
                severity: 'error',
                display: true,
              }),
            );
            return;
          }
        }
      }

      if (currentTemplate.publishAction === PublishAction.ToPublic && (!currentTemplate.labels || currentTemplate.labels.length === 0)) {
        dispatch(openSnackbar({ message: t('errorLabelRequiredForPublishing'), severity: 'error', display: true }));
        return;
      }

      const payload = modifyFlowchart(JSON.parse(JSON.stringify(currentTemplate)) as PostOrPutWorkflowTemplateDto);
      if (templateId === 0) {
        payload.steps.forEach((element) => {
          if (!payload.layouts.find((x) => x.stepId === element.id)) {
            payload.layouts.push({
              stepId: element.id,
              description: '<Empty layout>',
              xml: '',
              layoutType: 'DataView',
            });
          }
        });

        try {
          const result = await postWorkflowTemplate({ data: payload, teamId: activeTeam?.id ?? 1 }).unwrap();
          dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
          refetch();
          setTimeout(() => navigate(RoutePath.WorkflowTemplatesPath), 1000);
        } catch (err) {
          console.debug('Failed while attempting to submit workflow template', err);
        }
      } else {
        try {
          for (const element of payload.steps) {
            console.log('Processing step:', element.id);
            if (!payload.layouts.find((x) => x.stepId === element.id)) {
              try {
                console.log('Fetching layout for step:', element.id);
                const data = await getStepLayout({
                  layoutType: 'DataView',
                  stepId: element.id,
                  stepVersion: element.version,
                  teamId: activeTeam?.id ?? 1,
                }).unwrap();

                console.log('Received layout data:', JSON.stringify(data));
                payload.layouts.push({
                  stepId: data.stepId,
                  description: data.description,
                  xml: data.xml,
                  layoutType: 'DataView',
                });
                console.log('Updated layouts:', JSON.stringify(payload.layouts));
              } catch (err) {
                console.error('Failed while attempting to get step layout', err);
              }
            } else {
              console.log('Layout already exists for step:', element.id);
            }
          }

          console.log('Final payload before PUT:', JSON.stringify(payload));
          const result = await putWorkflowTemplate({ data: payload, teamId: activeTeam?.id ?? 1, templateId }).unwrap();
          dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
          refetch();
          setTimeout(() => navigate(RoutePath.WorkflowTemplatePath.replace(':templateId', result.templateId.toString())), 1000);
        } catch (err) {
          console.error('Failed while attempting to submit workflow template', err);
        }
      }
    }
  };

  const modifyFlowchart = (templateData: PostOrPutWorkflowTemplateDto) => {
    let firstFlowMedia = null;
    const allDestinationSteps = new Map<string, any>();

    for (const step of templateData.steps) {
      const flowMedia = step.stepConfigurations.find((config) => config.mediaConfiguration.typeName === 'FlowControlMediaConfigurationDto');

      if (flowMedia) {
        if (!firstFlowMedia) {
          firstFlowMedia = flowMedia;
        }

        if (flowMedia.mediaConfiguration.destinationSteps) {
          for (const destStep of flowMedia.mediaConfiguration.destinationSteps) {
            const key = `${destStep.stepId}-${destStep.targetStepIndex}`;
            allDestinationSteps.set(key, destStep);
          }
        }
      }
    }

    if (!firstFlowMedia) {
      return templateData;
    }

    firstFlowMedia.mediaConfiguration.destinationSteps = Array.from(allDestinationSteps.values());

    return templateData;
  };

  const publishActionOptions = [
    { value: PublishAction.UpdateOnly, label: t('Unpublish') },
    { value: PublishAction.ToPrivate, label: t('Private') },
    { value: PublishAction.ToPublic, label: t('Published') },
  ];
  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid container item xs={templateId !== 0 ? 10 : 12}>
          {isEditMode && (
            <>
              <Grid container item xs={7} spacing={2}>
                <Grid item xs={7}>
                  <TextField
                    name="name"
                    autoComplete="nope"
                    label={t('name')}
                    placeholder={t('name') as string}
                    fullWidth
                    value={currentTemplate?.name || ''}
                    onChange={handleNameChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    select
                    name="publishStatus"
                    label={t('publishStatus')}
                    placeholder={t('publishStatus') as string}
                    fullWidth
                    variant="outlined"
                    value={currentTemplate?.publishAction ?? ''}
                    onChange={handlePublishActionChange}>
                    {publishActionOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid item xs={5} className="flex items-center gap-2 justify-end">
                <Button variant="contained" color="secondary" onClick={handleReset} disabled={isLoading}>
                  {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                  {t('resetButtonText')}
                </Button>
                <Button variant="contained" color="info" onClick={() => handleAddStep(false)} disabled={isLoading}>
                  {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                  {t('addStep')}
                </Button>
                <Button variant="contained" color="info" onClick={() => handleAddStep(true)} disabled={isLoading || !workflowServiceTemplates.length}>
                  {isLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                  {t('addService')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={postIsLoading || putIsLoading || isLoading || getStepLayoutLoading}>
                  {(postIsLoading || getStepLayoutLoading || putIsLoading || isLoading) && (
                    <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />
                  )}
                  {t('submitButtonText')}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        {templateId !== 0 && !isLoading && currentTemplate && currentTemplate?.publishAction !== PublishAction.ToPublic && (
          <Grid item xs={2} className="flex justify-end items-center">
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={isEditMode}
                    onChange={(_e, checked) => {
                      if (checked) {
                        dispatch(setResetFlag(true));
                      }
                      setIsEditMode(checked);
                    }}
                    color="primary"
                    name="isEditMode"
                  />
                }
                label={t('Edit Mode')}
              />
            </FormGroup>
          </Grid>
        )}
      </Grid>
      {isEditMode && (
        <Grid item xs={12}>
          <Autocomplete
            className="py-4"
            multiple
            id="tags-filled"
            options={[] as string[]}
            freeSolo
            value={currentTemplate?.labels || []}
            onChange={handleLabelsChange}
            renderTags={(value: readonly string[], getTagProps) =>
              value.map((option: string, index: number) => {
                const { key, ...tagProps } = getTagProps({ index });
                return <Chip variant="filled" label={option} key={key} {...tagProps} />;
              })
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label={t('typeAndPressEnterToAddLabels')} placeholder={t('typeAndPressEnterToAdd') as string} />
            )}
          />
        </Grid>
      )}
      {isEditMode ? (
        <WorkflowFlow workflow={currentTemplate} isLoading={isLoading} addStep={handleAddStep} />
      ) : workflowItem ? (
        <WorkflowTable workflow={workflowItem?.workflowDto} isLoading={isLoading} />
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default WorkflowTemplate;
