import React from 'react';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from '@hooks';
import { TemplateManagement, ReportDisplay } from '@components';

const Report: React.FC = () => {
  const { generatedReport } = useAppSelector((state) => state.report);

  return (
    <Box className="p-4">
      <Typography variant="h4" className="mb-4">
        Report Generator
      </Typography>
      <TemplateManagement />
      {generatedReport.data.length > 0 && <ReportDisplay />}
    </Box>
  );
};

export default Report;
