import React, { useEffect, useRef } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Paper, Select, MenuItem, Typography, List, ListItem, SelectChangeEvent, TextField, IconButton } from '@mui/material';
import { WorkflowServiceDto, WorkflowServiceTemplateDto, WorkflowStepNodeData } from '@interfaces';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useTranslation } from 'react-i18next';
import { updateStepService, removeTemplateStep } from '@slices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { red } from '@constants';

type Props = {
  data: WorkflowStepNodeData;
  stepId: number;
};

const WorkflowServiceNode = ({ data, stepId }: Props) => {
  const { t } = useTranslation('pano');
  const inputRef = useRef<HTMLInputElement>(null);
  const cursorPositionRef = useRef<number | null>(null);
  const dispatch = useAppDispatch();
  if (!data.service) {
    return null;
  }

  useEffect(() => {
    if (inputRef.current && cursorPositionRef.current !== null) {
      inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
    }
  }, [data.label]);

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const newValue = e.target.value;
    cursorPositionRef.current = e.target.selectionStart;
    if (data.onLabelChange) {
      data.onLabelChange(newValue, false);
    }
  };

  const { workflowServiceTemplates } = useAppSelector((x) => x.mahon);

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(removeTemplateStep(stepId));
  };

  const handleServiceTemplateChange = (event: SelectChangeEvent<number>) => {
    const newTemplateId = event.target.value as number;
    const newTemplate = workflowServiceTemplates.find((template) => template.id === newTemplateId);

    if (newTemplate && data.service) {
      const updatedService: WorkflowServiceDto = {
        ...data.service,
        workFlowServiceTemplateId: newTemplateId,
        statusRoutes: newTemplate.statuses,
      };
      dispatch(updateStepService({ service: updatedService, stepId }));
    }
  };

  return (
    <Paper className="space-y-4 p-2.5 border rounded cursor-pointer !w-44 relative">
      <IconButton
        size="small"
        className="!absolute !right-1 !top-1 nodrag"
        onClick={handleDelete}
        sx={{
          width: 20,
          height: 20,
        }}>
        <FontAwesomeIcon icon={faXmark} style={{ color: red[500], fontSize: 14 }} />
      </IconButton>

      <Handle type="target" position={Position.Left} style={{ width: 12, height: 12 }} />
      <div className="flex w-full items-center space-x-1 !mt-6">
        <div className="w-5">{data.index}.</div>
        <TextField
          inputRef={inputRef}
          className="nodrag"
          name="name"
          autoComplete="off"
          label={t('name')}
          placeholder={t('name') as string}
          fullWidth
          value={data.label}
          onChange={handleLabelChange}
          onBlur={() => {
            if (data.onLabelChange) data.onLabelChange(data.label as string, true);
          }}
          onMouseDown={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          variant="outlined"
        />
      </div>

      <Select value={data.service.workFlowServiceTemplateId} onChange={handleServiceTemplateChange} fullWidth variant="outlined" className="nodrag">
        {workflowServiceTemplates.map((template: WorkflowServiceTemplateDto) => (
          <MenuItem key={template.id} value={template.id}>
            {template.name}
          </MenuItem>
        ))}
      </Select>
      <List className="!m-0">
        {data.service.statusRoutes.map(
          (route, index) =>
            route.progressStepIndex > -1 && (
              <ListItem key={index}>
                <Typography variant="body2">{route.name}</Typography>
                <Handle type="source" position={Position.Right} id={`${route.name}-${stepId}`} style={{ width: 12, height: 12 }} />
              </ListItem>
            ),
        )}
      </List>
    </Paper>
  );
};

export default WorkflowServiceNode;
