import {
  useGetApplicationsQuery,
  useGetCommandsQuery,
  useGetTeamMembershipsQuery,
  useGetTeamQuery,
  useGetTeamServicesQuery,
  usePutCommandsMutation,
} from '@apis';
import { ShortcutEditor, SnippetEditor, TableIcons } from '@components';
import { RoutePath } from '@constants';
import { UnsavedChangesContext, useAppDispatch, useAppSelector } from '@hooks';
import { CommandTableDataItem } from '@interfaces';
import MaterialTable, { MTableAction } from '@material-table/core';
import {
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { openSnackbar, resetCommands, setBreadcrumbs, setHelmet, setTeamNavigationContext } from '@slices';
import { getKeyCodeFromVirtualKey, getKeyNameFromKeyCode, getVirtualKeyFromKeyCode } from '@utils';
import React, { createRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from 'oidc-react';

const EchoCommands = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const tableRef = createRef();
  const parms = useParams();
  const teamId = parms?.teamId ? parseInt(parms.teamId) : undefined;
  const [typeToLoad, setTypeToLoad] = useState<string>('');
  const { team, teamServices } = useAppSelector((x) => x.team);
  const { userRights } = useAppSelector((x) => x.app);
  const { userData } = useAuth();
  const { commands, applications } = useAppSelector((x) => x.echo);
  const { teamMemberships } = useAppSelector((x) => x.user);
  const [initialData, setInitialData] = useState<CommandTableDataItem[]>([]);
  const [dataHasChanged, setDataChange] = useState(false);
  const unsavedChangesContext = useContext(UnsavedChangesContext);
  const [allValidationData, setAllValidationData] = useState<any[]>([]);
  const [pendingApplicationSelection, setPendingApplicationSelection] = useState<string | number | null>(null);
  const RowValidationData = {
    validationErrorCheck: false,
    validationError: '',
    validationText: '',
    validationTableRowId: null,
  };
  const [tableData, setTableData] = useState<{ commandData: CommandTableDataItem[] }>({
    commandData: [],
  });
  const [applicationDropdown, setApplicationDropdown] = useState<{ selection: string | number }>({
    selection: '',
  });

  const doWeNeedToClearValidationdata = () => {
    //@ts-ignore
    if (allValidationData.length > 0 && tableRef.current != null && tableRef.current.dataManager != null) {
      //@ts-ignore
      if (!tableRef.current.dataManager.bulkEditOpen && !tableRef.current.state.showAddRow) {
        onClearAllValidationData();
      }
    }
  };

  const onClearAllValidationData = () => {
    setAllValidationData([]);
  };

  const addItemToValidation = (newValidationData: any): void => {
    console.info('pushing newValidationData', newValidationData);
    setAllValidationData((state) => {
      state.push(newValidationData);

      return state;
    });
  };

  const checkForChanges = (currentData: CommandTableDataItem[]) => {
    if (!initialData || !currentData) return false;

    if (initialData.length !== currentData.length) {
      return true;
    }

    return currentData.some((row, index) => {
      const initialRow = initialData[index];
      if (!initialRow) return true;

      return (
        row.spokenphrase !== initialRow.spokenphrase ||
        row.description !== initialRow.description ||
        row.type !== initialRow.type ||
        JSON.stringify(row.shortcuts) !== JSON.stringify(initialRow.shortcuts)
      );
    });
  };

  const validateData = (newData: any) => {
    let hasError = false;
    let validationError = '';
    let validationText = '';
    let validationTableRowId = null;

    //Key value pairs of required fields and translated error txt.
    let requiredFields = {
      spokenphrase: t('echoCommandShortcutSnippetValidation'),
      shortcuts: t('echoCommandShortcutSnippetValidation'),
    };

    //Make sure we have REQUIRED fields, if a field has no newData it will not be in the (json)newData.
    let hasRequiredFields = false;
    for (const field in requiredFields) {
      validationError = field;
      validationTableRowId = newData.tableData ? newData.tableData.id : newData.id;
      //@ts-ignore
      validationText = requiredFields[field];
      // eslint-disable-next-line no-prototype-builtins
      hasRequiredFields = newData.hasOwnProperty(field) && newData[field] != '' && newData[field] != undefined;
      if (!hasRequiredFields) break;
    }

    hasError = !hasRequiredFields;

    if (hasError)
      addItemToValidation({
        validationError: hasError ? validationError : '',
        validationText: hasError ? validationText : '',
        validationErrorCheck: hasError,
        validationTableRowId: validationTableRowId,
      });

    return !hasError;
  };

  useEffect(() => {
    if (unsavedChangesContext) {
      unsavedChangesContext.setIsDirty(dataHasChanged);
    }
  }, [dataHasChanged]);

  useGetTeamQuery(teamId as number, {
    refetchOnMountOrArgChange: true,
    skip: !teamId,
  });
  useGetTeamServicesQuery(teamId as number, {
    refetchOnMountOrArgChange: true,
    skip: !teamId,
  });

  useGetTeamMembershipsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    skip: !userData,
  });

  const {
    isLoading: isGettingApplications,
    isSuccess: isApplicationsSuccess,
    refetch: refetchApplications,
  } = useGetApplicationsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const {
    isFetching: isGettingCommands,
    isSuccess,
    refetch,
  } = useGetCommandsQuery(
    { teamId, type: typeToLoad },
    {
      refetchOnMountOrArgChange: true,
      skip: (typeToLoad === 'team' && !teamId) || !isApplicationsSuccess || !typeToLoad,
    },
  );

  const [updateCommands, { isLoading: isUpdatingCommands }] = usePutCommandsMutation();

  const handleApplicationSelection = (event: SelectChangeEvent<string | number>): void => {
    const newSelection = event.target.value;

    if (dataHasChanged) {
      setPendingApplicationSelection(newSelection);
      unsavedChangesContext?.setShowPrompt(true);
      return;
    }
    setApplicationDropdown({ selection: newSelection });
  };

  useEffect(() => {
    if (!unsavedChangesContext?.showPrompt && !unsavedChangesContext?.isDirty && pendingApplicationSelection !== null) {
      setApplicationDropdown({ selection: pendingApplicationSelection });
      setDataChange(false);
      buildTableData();
      setPendingApplicationSelection(null);
    } else if (!unsavedChangesContext?.showPrompt && pendingApplicationSelection !== null) {
      setPendingApplicationSelection(null);
    }
  }, [unsavedChangesContext?.showPrompt, unsavedChangesContext?.isDirty]);

  const handleSubmitCommands = () => {
    console.debug('handleSubmitCommands', tableRef.current);
    //@ts-ignore
    if (tableRef.current.state.lastEditingRow === undefined && !tableRef.current.state.showAddRow) {
      let submissionData = {
        ApplicationId: applicationDropdown.selection,
        CommandDescriptors: [],
      } as any;

      var spokenPhrases = tableData.commandData.flatMap((c) => c.spokenphrase);

      if (new Set(spokenPhrases).size !== spokenPhrases.length) {
        dispatch(openSnackbar({ message: t('echoDuplicateCommandsError'), severity: 'error', display: true }));

        return;
      }

      tableData.commandData.forEach((commandDescriptor) => {
        if (commandDescriptor.type === 'SimpleCommandDescriptor') {
          if (Array.isArray(commandDescriptor.shortcuts)) {
            var keyCodeSequences = commandDescriptor.shortcuts?.map((keyCodeSequence) => {
              return {
                commandKeyCodes: keyCodeSequence.commandKeyCodes.map((commandKeyCode) => {
                  return {
                    key: getVirtualKeyFromKeyCode(commandKeyCode.keyCode),
                  };
                }),
              };
            });

            submissionData.CommandDescriptors.push({
              TypeName: commandDescriptor.type,
              Description: commandDescriptor.description,
              CommandText: commandDescriptor.spokenphrase,
              KeyCodeSequences: keyCodeSequences,
            });
          }
        } else {
          submissionData.CommandDescriptors.push({
            TypeName: commandDescriptor.type,
            Description: commandDescriptor.description,
            CommandText: commandDescriptor.spokenphrase,
            Snippet: commandDescriptor.shortcuts,
          });
        }
      });

      updateCommands({ data: submissionData, teamId, type: typeToLoad })
        .unwrap()
        .then(() => {
          refetch();
          dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
        })
        .catch((err) => {
          console.debug('Failed while attempting to save commands', err);
          refetch();
          return;
        });
      setDataChange(false);
    } else {
      dispatch(openSnackbar({ message: t('saveCurrentChangesFirst'), severity: 'error', display: true }));
    }
  };

  const handleReset = () => {
    refetchApplications();
    setDataChange(false);
    buildTableData();
  };

  useEffect(() => {
    switch (location.pathname) {
      case RoutePath.UserCommandsPath:
        dispatch(resetCommands([]));
        setTypeToLoad('user');
        break;
      case RoutePath.AdminCommandsPath:
        dispatch(resetCommands([]));
        setTypeToLoad('master');
        break;
      case RoutePath.TeamCommandsPath.replace(':teamId', `${teamId}`):
        dispatch(resetCommands([]));
        setTypeToLoad('team');
        break;
    }
  }, [location]);

  useEffect(() => {
    let breadcrumbs = [] as any[];
    let teamsLink = { text: t('teamsText') } as { text: string; link?: string };

    switch (typeToLoad) {
      case 'user':
        breadcrumbs = [{ text: t('userDashboardPageTitle'), link: '/user' }, { text: t('echoCommandsButton') }];
        break;
      case 'master':
        breadcrumbs = [{ text: t('echoCommandsButton') }];
        break;
      case 'team':
        if (teamServices) {
          dispatch(setTeamNavigationContext({ teamServices, teamId: `${teamId}`, t }));
        }

        if (teamMemberships) {
          const teamOwnerships = teamMemberships.filter((tm) => tm.ownerEmail === userData?.profile.email);

          if (teamOwnerships.length > 1) {
            teamsLink = { text: t('teamsText'), link: '/teams' };
          }
        }

        // Overwrite if sysadmin or you are an admin of more than 1 team
        if (userRights.isSysAdmin || userRights.administeredTeams.length > 1) {
          teamsLink = { text: t('teamsText'), link: '/teams' };
        }
        if (team) {
          breadcrumbs = [teamsLink, { text: team.name, link: `/teams/${teamId}` }, { text: t('echoCommandsButton') }];
        }
        break;
    }

    dispatch(setBreadcrumbs(breadcrumbs));
    dispatch(setHelmet({ title: t('htmlTitleEchoCommands') }));
  }, [typeToLoad, team, teamServices]);

  const getAndSortCommandData = () => {
    return commands.map((appCommandDto) => {
      var commandDescriptors = appCommandDto.commandDescriptors
        .filter(
          (commandDescriptor) => commandDescriptor.typeName === 'TextSnippetCommandDescriptor' || commandDescriptor.typeName === 'SimpleCommandDescriptor',
        )
        .map((commandDescriptor) => {
          if (commandDescriptor.typeName === 'TextSnippetCommandDescriptor') {
            let fixDescription = '';
            if (commandDescriptor.description !== null) {
              fixDescription = commandDescriptor.description;
            }

            return {
              commandText: commandDescriptor.commandText,
              description: fixDescription,
              snippet: commandDescriptor.snippet,
              typeName: commandDescriptor.typeName,
            };
          }

          if (commandDescriptor.typeName === 'SimpleCommandDescriptor') {
            let fixDescription = '';
            if (commandDescriptor.description !== null) {
              fixDescription = commandDescriptor.description;
            }

            var keyCodeSequences = commandDescriptor.keyCodeSequences?.map((keyCodeSeq) => {
              var keyCodes = keyCodeSeq.commandKeyCodes.map((keyCode) => {
                var code = getKeyCodeFromVirtualKey(keyCode.key);
                var keyName = getKeyNameFromKeyCode(code);

                return {
                  key: keyName,
                  keyCode: code,
                };
              });

              return {
                commandKeyCodes: keyCodes,
              };
            });

            return {
              commandText: commandDescriptor.commandText,
              description: fixDescription,
              keyCodeSequences: keyCodeSequences,
              typeName: commandDescriptor.typeName,
            };
          }
        });

      return {
        commandDescriptors: commandDescriptors,
        name: appCommandDto.name,
        id: appCommandDto.id,
      };
    });
  };

  useEffect(() => {
    switch (location.pathname) {
      case RoutePath.UserCommandsPath:
        dispatch(resetCommands([]));
        setTypeToLoad('user');
        break;
      case RoutePath.AdminCommandsPath:
        dispatch(resetCommands([]));
        setTypeToLoad('master');
        break;
      case RoutePath.TeamCommandsPath.replace(':teamId', `${teamId}`):
        dispatch(resetCommands([]));
        setTypeToLoad('team');
        break;
    }

    if (isApplicationsSuccess && applications !== null && applications.length > 0) {
      setTableData({ commandData: [] });

      var applicationData = applications
        .filter((application) => application !== undefined)
        .map((application) => {
          return {
            id: application.id,
            name: application.name,
          };
        });

      setApplicationDropdown({ selection: applicationData[0].id });
    }
  }, [isApplicationsSuccess]);

  useEffect(() => {
    if (isSuccess && commands !== null) {
      buildTableData();
      setInitialData([...tableData.commandData]);
    }
  }, [isSuccess]);

  const buildTableData = (): void => {
    const sortedData = getAndSortCommandData();
    if (sortedData.length == 0) return;

    const tableData: CommandTableDataItem[] = [];

    var app = sortedData.find((app) => app.id === applicationDropdown.selection);
    if (!app) {
      setTableData({ commandData: tableData });
      return;
    }

    app.commandDescriptors.forEach((command) => {
      if (command === undefined || command === null) return;

      let fixDescription = '';
      if (command.description !== null) {
        fixDescription = command.description;
      }

      if (command.typeName === 'TextSnippetCommandDescriptor') {
        tableData.push({
          id: uuidv4(),
          type: command.typeName,
          description: fixDescription,
          spokenphrase: command.commandText,
          shortcuts: command.snippet,
        });
      }

      if (command.typeName === 'SimpleCommandDescriptor') {
        tableData.push({
          id: uuidv4(),
          type: command.typeName,
          description: fixDescription,
          spokenphrase: command.commandText,
          shortcuts: command.keyCodeSequences,
        });
      }
    });

    setTableData({ commandData: tableData });

    setInitialData([...tableData]);
    setDataChange(false);
  };

  useEffect(() => {
    if (applications !== null && commands !== null && applicationDropdown.selection) {
      buildTableData();
    }
  }, [applicationDropdown.selection, commands]);

  let applicationDropdownItems: JSX.Element[] = [];
  if (isApplicationsSuccess && applications !== null) {
    applications.map((app) => {
      applicationDropdownItems.push(
        <MenuItem key={app.id} value={app.id}>
          {app.name}
        </MenuItem>,
      );
    });
  }

  const isButtonLoading = isUpdatingCommands || isGettingApplications || isGettingCommands;

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            {t('echoCommandsPageTitle')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('echoCommandsPageSubtitle')}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper className="p-5 w-full space-y-4">
            <Grid item xs={12} sm={12} md={12} lg={12} className="p-2">
              <FormControl variant="outlined">
                <InputLabel htmlFor="echoCommandsApplicationSelection">{t('echoCommandsApplicationSelection')}</InputLabel>
                <Select
                  id="echoCommandsApplicationSelection"
                  label={t('echoCommandsApplicationSelection')}
                  value={applicationDropdown.selection}
                  onChange={handleApplicationSelection}>
                  {applicationDropdownItems}
                </Select>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper className="p-5 w-full space-y-4">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MaterialTable
                icons={TableIcons}
                tableRef={tableRef}
                isLoading={isGettingApplications || isGettingCommands}
                options={{
                  sorting: false,
                  actionsColumnIndex: -1,
                  addRowPosition: 'first',
                  draggable: false,
                  pageSize: 5,
                  pageSizeOptions: [5, 10],
                }}
                columns={[
                  {
                    title: t('type'),
                    field: 'type',
                    initialEditValue: 'SimpleCommandDescriptor',
                    searchable: false,
                    render: (rowData) =>
                      rowData.type === 'SimpleCommandDescriptor' ? <Chip label={t('keyboardShortcut')} /> : <Chip label={t('textSnippet')} />,
                    editComponent: (rowData) => {
                      return rowData.rowData.shortcuts ? (
                        rowData.rowData.type === 'SimpleCommandDescriptor' ? (
                          <Chip label={t('keyboardShortcut')} />
                        ) : (
                          <Chip label={t('textSnippet')} />
                        )
                      ) : (
                        <FormControl variant="outlined">
                          <InputLabel htmlFor="selectCommandDescriptorType">{t('selectCommandDescriptorType')}</InputLabel>
                          <Select
                            id="selectCommandDescriptorType"
                            label={t('selectCommandDescriptorType')}
                            value={rowData.rowData.type}
                            onChange={(e) => rowData.onChange(e.target.value)}>
                            <MenuItem value="SimpleCommandDescriptor">{t('keyboardShortcut')}</MenuItem>
                            <MenuItem value="TextSnippetCommandDescriptor">{t('textSnippet')}</MenuItem>
                          </Select>
                        </FormControl>
                      );
                    },
                  },
                  { title: t('echoCommandColumnDescription'), field: 'description', initialEditValue: '' },
                  { title: t('echoCommandColumnSpokenPhrase'), field: 'spokenphrase', initialEditValue: '' },
                  {
                    title: '',
                    field: 'shortcuts',
                    initialEditValue: '',
                    searchable: false,
                    render: (rowData) => {
                      //Due to the issue in MT bulk edit we have to check and clear validation here as there is no event to hook on to when we discard edits.
                      doWeNeedToClearValidationdata();

                      return rowData.type === 'SimpleCommandDescriptor' ? (
                        <ShortcutEditor value={rowData.shortcuts} readonly={true} type={rowData.type} />
                      ) : (
                        <SnippetEditor value={rowData.shortcuts} readonly={true} />
                      );
                    },
                    editComponent: (props) => {
                      // Adding a new row will not have any tableData in the rowData but while editing a guid is assigned to rowdata.
                      let newItem = props.rowData.tableData ? false : true;
                      let rowId = newItem ? props.rowData.id : props.rowData.tableData.id;
                      let validationData = allValidationData.find((ele) => ele.validationTableRowId === rowId);
                      return props.rowData.type === 'SimpleCommandDescriptor' ? (
                        <ShortcutEditor value={props.value} onChange={props.onChange} type={props.rowData.type} validationData={validationData} />
                      ) : (
                        <SnippetEditor value={props.value} originalValue={props.value} onChange={props.onChange} validationData={validationData} />
                      );
                    },
                  },
                ]}
                data={tableData.commandData}
                title={t('echoCommandsTableHeader')}
                components={{
                  Action: (props) => {
                    if (typeof props.action === typeof Function || props.action.tooltip !== 'Discard all changes') {
                      return <MTableAction {...props} />;
                    } else {
                      return props.action.hidden ? (
                        <></>
                      ) : (
                        <Tooltip title={t('discardChanges')}>
                          <IconButton
                            className="!w-8 flex justify-center"
                            onClick={() => {
                              props.action.onClick();
                              buildTableData();
                            }}>
                            <TableIcons.Cross />
                          </IconButton>
                        </Tooltip>
                      );
                    }
                  },
                  EditField: (tableState) => {
                    // Adding a new row will not have any tableData in the rowData.
                    let newItem = tableState.rowData.tableData ? false : true;
                    let rowId = newItem ? tableState.rowData.id : tableState.rowData.tableData.id;
                    let currentValidationData = allValidationData.find((ele) => ele.validationTableRowId === rowId);
                    currentValidationData = currentValidationData ? currentValidationData : RowValidationData;
                    return (
                      <TextField
                        variant="outlined"
                        inputProps={{ maxLength: 40 }}
                        style={tableState.columnDef.type === 'numeric' ? { float: 'right' } : {}}
                        type={tableState.columnDef.type === 'numeric' ? 'number' : 'text'}
                        placeholder={tableState.columnDef.title}
                        value={tableState.value === undefined ? '' : tableState.value}
                        onChange={(event) => tableState.onChange(event.target.value)}
                        error={currentValidationData.validationError === tableState.columnDef.field}
                        helperText={currentValidationData.validationError === tableState.columnDef.field ? currentValidationData.validationText : ''}
                      />
                    );
                  },
                }}
                editable={{
                  onRowAddCancelled: () => doWeNeedToClearValidationdata(),
                  onRowAdd: (newData) =>
                    new Promise<void>((resolve, reject) => {
                      const data = tableData.commandData;
                      newData.id = uuidv4();

                      if (!validateData(newData)) {
                        reject();
                        return;
                      }

                      data.push(newData);
                      setTableData({
                        commandData: data,
                      });
                      onClearAllValidationData();
                      setDataChange(checkForChanges(data));
                      resolve();
                    }),
                  onBulkUpdate: (changes) =>
                    new Promise<void>((resolve, reject) => {
                      if (changes != null && Object.keys(changes).length != 0) {
                        onClearAllValidationData();
                        const data = tableData.commandData;
                        var result = true;
                        let newChangedData = [];
                        for (const i in changes) newChangedData.push({ index: i, data: changes[i] });
                        for (const changedRow of newChangedData) {
                          if (!validateData(changedRow.data.newData)) result = false;
                        }
                        if (!result) {
                          reject();
                          return;
                        }
                        for (const changedRow of newChangedData) {
                          const index = data.findIndex((x) => x.id === changedRow.index);
                          data[index] = changedRow.data.newData;
                        }
                        setTableData({
                          commandData: data,
                        });
                        onClearAllValidationData();
                      }
                      onClearAllValidationData();
                      setDataChange(true);
                      resolve();
                    }),
                  onRowDelete: (oldData) =>
                    new Promise<void>((resolve) => {
                      let data = [...tableData.commandData];
                      const index = data.findIndex((x) => x.id === oldData?.id);
                      data.splice(index, 1);
                      setTableData({ commandData: data });
                      setDataChange(checkForChanges(data));
                      resolve();
                    }),
                }}
              />
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} className="justify-end flex pt-5">
          <Button
            variant="contained"
            color="secondary"
            className="!mr-5"
            disabled={!dataHasChanged || isUpdatingCommands || isGettingApplications || isGettingCommands}
            onClick={handleReset}>
            <>{isButtonLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}</>
            {t('cancelButtonText')}
          </Button>

          <Button
            variant="contained"
            color="primary"
            disabled={!dataHasChanged || isUpdatingCommands || isGettingApplications || isGettingCommands}
            onClick={handleSubmitCommands}>
            <>{isButtonLoading && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}</>
            {t('submitButtonText')}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EchoCommands;
