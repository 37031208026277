import { useGetTeamConfigurationQuery, useGetTeamQuery, useGetTeamServicesQuery, usePutTeamConfigurationMutation } from '@apis';
import { LookupConfigMediaEditor, LookupConfigMediaSlider, TableIcons } from '@components';
import { TeamConfigurationClinicalSystemItem, TeamConfigurationLookupItem, TeamConfigurationPriorityItem } from '@interfaces';
import MaterialTable from '@material-table/core';
import {
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { openSnackbar, setBreadcrumbs, setHelmet, setTeamNavigationContext } from '@slices';
import { v4 as uuidv4 } from 'uuid';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { UnsavedChangesContext, useAppDispatch, useAppSelector } from '@hooks';
import moment from 'moment';

const TeamConfiguration = (): JSX.Element => {
  const { t } = useTranslation('pano');
  const dispatch = useAppDispatch();
  const parms = useParams();
  const navigate = useNavigate();
  const teamId = parms?.teamId ?? '';
  const { teamConfiguration } = useAppSelector((x) => x.mahon);
  const { team, teamServices } = useAppSelector((x) => x.team);
  const slideRef = createRef<HTMLDivElement>();
  const isRefetchingRef = useRef(false);
  const initialStateRef = useRef({
    lookups: [] as TeamConfigurationLookupItem[],
    priority: null as TeamConfigurationPriorityItem | null,
    clinical: {
      mediaConfigurationId: 0,
      type: 'None',
      uri: null,
      customerNumber: null,
      isTestSystem: false,
    },
  });
  const [hasFormChanged, setHasFormChanged] = useState<boolean>(false);
  const unsavedChangesContext = useContext(UnsavedChangesContext);

  useEffect(() => {
    if (unsavedChangesContext) {
      unsavedChangesContext.setIsDirty(hasFormChanged);
    }
  }, [hasFormChanged]);

  useGetTeamQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  useGetTeamServicesQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });
  const {
    refetch,
    isLoading: isGettingTeamConfiguration,
    isSuccess,
    isError,
  } = useGetTeamConfigurationQuery(parseInt(teamId), {
    refetchOnMountOrArgChange: true,
    skip: teamId === '',
  });

  const [putTeamConfiguration, { isLoading: isUpdatingConfiguration }] = usePutTeamConfigurationMutation();
  const [selectedPriorityRows, setSelectedPriorityRows] = useState<any[]>([]);
  const [validation, setValidation] = useState<{
    validationErrorCheck: boolean;
    validationError: string;
    validationText: string;
  }>({
    validationErrorCheck: false,
    validationError: '',
    validationText: '',
  });

  const [priority, setPriority] = useState<TeamConfigurationPriorityItem | null>(null);

  const [clinical, setClinical] = useState<TeamConfigurationClinicalSystemItem>({
    mediaConfigurationId: 0,
    type: 'None',
    uri: null,
    customerNumber: null,
    isTestSystem: false,
  });

  const [isTestSystem, setIsTestSystem] = useState<boolean>(false);

  const handleClinicalType = (event: SelectChangeEvent<string>): void => {
    if (event.target.value != 'Emis') {
      setClinical({
        ...clinical,
        uri: null,
        customerNumber: null,
      });
    }

    setClinical({ ...clinical, type: event.target.value });
  };

  const handleClinicalIPChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setClinical({ ...clinical, uri: event.target.value });
  };

  const handleClinicalCustomerNumberChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setClinical({ ...clinical, customerNumber: event.target.value });
  };

  const handleIsTestSystemChange = () => {
    setIsTestSystem(!isTestSystem);
  };

  const handleDisabledColourPicker = () => {
    // This is here to stop the control moving from uncontrolled to controlled state.
  };

  useEffect(() => {
    if (isSuccess && teamConfiguration && (!initialStateRef.current.lookups.length || isRefetchingRef.current)) {
      const initialClinical = teamConfiguration.clinicalSystem || {
        mediaConfigurationId: 0,
        type: 'None',
        uri: null,
        customerNumber: null,
        isTestSystem: false,
      };

      if (teamConfiguration.clinicalSystem) {
        setClinical(teamConfiguration.clinicalSystem);
        setIsTestSystem(teamConfiguration?.clinicalSystem.isTestSystem);
      }
      setPriority(teamConfiguration.priority ?? null);

      initialStateRef.current = {
        lookups: JSON.parse(JSON.stringify(teamConfiguration.lookups)),
        priority: teamConfiguration.priority ? JSON.parse(JSON.stringify(teamConfiguration.priority)) : null,
        clinical: JSON.parse(JSON.stringify(initialClinical)),
      };
      setHasFormChanged(false);
      isRefetchingRef.current = false;
    }
    if (isSuccess && team) {
      dispatch(setBreadcrumbs([{ text: t('teamPageTitle'), link: '/teams' }, { text: team.name, link: `/teams/${teamId}` }, { text: t('configuration') }]));
    }
  }, [isSuccess, teamConfiguration]);

  useEffect(() => {
    if (teamConfiguration) {
      const hasLookupChanges = teamConfiguration.lookups.some((currentLookup, index) => {
        const initialLookup = initialStateRef.current.lookups[index];

        if (!initialLookup || currentLookup.mediaConfigurationId !== initialLookup.mediaConfigurationId) {
          return true;
        }

        const nameChanged = currentLookup.name !== initialLookup.name;
        const defaultValueChanged = currentLookup.defaultValue !== initialLookup.defaultValue;
        const isStickyChanged = currentLookup.isSticky !== initialLookup.isSticky;
        const lengthChanged = currentLookup.values.length !== initialLookup.values.length;

        const currentHasNewValues = currentLookup.values.some((value) => !initialLookup.values.includes(value));
        const initialHasRemovedValues = initialLookup.values.some((value) => !currentLookup.values.includes(value));

        const hasChanges = nameChanged || defaultValueChanged || isStickyChanged || lengthChanged || currentHasNewValues || initialHasRemovedValues;

        return hasChanges;
      });

      const hasPriorityChanges = (() => {
        if (!priority && !initialStateRef.current.priority) return false;
        if (!priority || !initialStateRef.current.priority) return true;

        return JSON.stringify(priority) !== JSON.stringify(initialStateRef.current.priority);
      })();

      const hasClinicalChanges =
        clinical.type !== initialStateRef.current.clinical.type ||
        clinical.uri !== initialStateRef.current.clinical.uri ||
        clinical.customerNumber !== initialStateRef.current.clinical.customerNumber ||
        isTestSystem !== initialStateRef.current.clinical.isTestSystem;
      setHasFormChanged(hasLookupChanges || hasPriorityChanges || hasClinicalChanges);
    }
  }, [teamConfiguration, priority, clinical, isTestSystem]);

  const onSubmit = () => {
    putTeamConfiguration({
      data: {
        clinicalSystem: {
          customerNumber: clinical.customerNumber,
          isTestSystem: isTestSystem,
          mediaConfigurationId: clinical.mediaConfigurationId,
          uri: clinical.uri,
          type: clinical.type,
        },
        priority: teamConfiguration?.priority
          ? ({
              mediaConfigurationId: priority?.mediaConfigurationId,
              values: priority?.values,
            } as TeamConfigurationPriorityItem)
          : undefined,
        lookups: teamConfiguration
          ? teamConfiguration?.lookups.map((item) => {
              return {
                mediaConfigurationId: item.mediaConfigurationId,
                name: item.name,
                values: item.values,
                defaultValue: item.defaultValue,
                isSticky: item.isSticky,
              } as TeamConfigurationLookupItem;
            })
          : [],
      },
      teamId: parseInt(teamId),
    })
      .unwrap()
      .then(() => {
        dispatch(openSnackbar({ message: t('changesSaved'), severity: 'success', display: true }));
      })
      .catch((err) => {
        console.debug('Failed while attempting to update team config mahon', err);
        return;
      });
  };

  const onReset = (): void => {
    isRefetchingRef.current = true;
    refetch();
  };

  const validateDuration = (duration: string | null): boolean => {
    if (duration) {
      return duration.search(/^((\d+)\.)?(2[0-3]|[0-1][0-9]):([0-5][0-9]):([0-5][0-9])$/) >= 0;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (team) {
      dispatch(setBreadcrumbs([{ text: t('teamPageTitle'), link: '/teams' }, { text: team.name, link: `/teams/${teamId}` }, { text: t('configuration') }]));
    }
    dispatch(setHelmet({ title: t('htmlTitleTeamConfiguration') }));
  }, [team]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => navigate(-1), 2500);
    }
  }, [isError]);

  useEffect(() => {
    if (teamServices) {
      dispatch(setTeamNavigationContext({ teamServices, teamId, t }));
    }
  }, [teamServices]);

  const validateDurations = (deadlineDuration: string | null, warningDuration: string | null): boolean => {
    if (!deadlineDuration || !warningDuration) return false;
    let deadlineParse = moment.duration(
      deadlineDuration.search(/^(2[0-3]|[0-1][0-9]):([0-5][0-9]):([0-5][0-9])$/) >= 0 ? '0.' + deadlineDuration : deadlineDuration,
    );
    let warningParse = moment.duration(
      warningDuration.search(/^(2[0-3]|[0-1][0-9]):([0-5][0-9]):([0-5][0-9])$/) >= 0 ? '0.' + warningDuration : warningDuration,
    );
    if (deadlineParse > warningParse) {
      return true;
    }
    return false;
  };

  let clinicalIPFix = clinical.uri ?? '';
  let clinicalCustomerNumberFix = clinical.customerNumber ?? '';

  const renderLookupConfigMediaEditors = (): JSX.Element[] => {
    let result = [] as JSX.Element[];
    teamConfiguration?.lookups.map((item, index) =>
      result.push(
        <div className="relative h-full w-full p-3" key={`${index}`}>
          <LookupConfigMediaEditor validation={validation} setValidation={setValidation} data={item} isLoading={isGettingTeamConfiguration} />
        </div>,
      ),
    );
    return result;
  };

  useEffect(() => {
    if (slideRef.current) slideRef.current!.className = 'w-1/2';
  }, [slideRef]);

  return (
    <React.Fragment>
      <Grid container spacing={2} className="!w-full">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Typography variant="h6" gutterBottom>
            {t('teamConfigPageTitle')}
          </Typography>

          <Typography variant="subtitle1" gutterBottom>
            {t('teamConfigurationPageSubtitle')}
          </Typography>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper className="p-5 w-full space-y-4">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography gutterBottom variant="h6">
                {t('teamConfigurationLookupHeader')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              <LookupConfigMediaSlider>{renderLookupConfigMediaEditors()}</LookupConfigMediaSlider>
            </Grid>
          </Paper>
        </Grid>

        {priority && (
          <Grid container item xs={12} sm={12} md={12} lg={12}>
            <Paper className="p-5 w-full space-y-4">
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography gutterBottom variant="h6">
                  {t('teamConfigurationPriorityHeader')}
                </Typography>
              </Grid>
              {selectedPriorityRows.length > 0 && (
                <div className="px-3 flex justify-end">
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => {
                      const selectedIds = selectedPriorityRows.map((row) => row.id);
                      const updatedValues = priority!.values.filter((value) => !selectedIds.includes(value.id));
                      setPriority({ ...priority!, values: updatedValues });
                      setSelectedPriorityRows([]);
                    }}>
                    {t('deleteSelected')} ({selectedPriorityRows.length})
                  </Button>
                </div>
              )}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
                  icons={TableIcons}
                  isLoading={isGettingTeamConfiguration}
                  options={{
                    sorting: false,
                    draggable: false,
                    pageSize: 5,
                    pageSizeOptions: [5, 10],
                    actionsColumnIndex: -1,
                    selection: true,
                  }}
                  columns={[
                    { title: t('name'), field: 'name' },
                    {
                      title: t('teamConfigurationPriorityColour'),
                      field: 'highlight',
                      editComponent: (props) => (
                        <input type="color" id="highlight" name="highlight" onChange={(e) => props.onChange(e.target.value)} value={props.value} />
                      ),
                      render: (rowData) => (
                        <input type="color" id="highlight" name="highlight" value={rowData.highlight} onChange={handleDisabledColourPicker} disabled />
                      ),
                      initialEditValue: '#7F7F7F',
                    },
                    { title: t('teamConfigurationPriorityDeadline'), field: 'deadlinePeriod', initialEditValue: '0.00:00:00' },
                    { title: t('teamConfigurationPriorityWarnAt'), field: 'warningPeriod', initialEditValue: '0.00:00:00' },
                  ]}
                  data={priority.values}
                  title={priority.name ?? t('teamConfigurationPriorityHeader')}
                  onSelectionChange={(rows, rowData) => {
                    if (!rowData) {
                      if (!rows.length) {
                        setSelectedPriorityRows([]);
                      } else {
                        setSelectedPriorityRows(priority!.values);
                      }
                    } else {
                      setSelectedPriorityRows(rows);
                    }
                  }}
                  components={{
                    EditField: (props) => {
                      return (
                        <TextField
                          variant="outlined"
                          style={props.columnDef.type === 'numeric' ? { float: 'right' } : {}}
                          type={props.columnDef.type === 'numeric' ? 'number' : 'text'}
                          placeholder={props.columnDef.title}
                          value={props.value === undefined ? '' : props.value}
                          onChange={(event) => props.onChange(event.target.value)}
                          error={validation.validationError == props.columnDef.field}
                          helperText={validation.validationError == props.columnDef.field ? validation.validationText : ''}
                        />
                      );
                    },
                  }}
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise<void>((resolve, reject) => {
                        let isDeadlineValidated = validateDuration(newData.deadlinePeriod);
                        let isWarningValidated = validateDuration(newData.warningPeriod);
                        let isWarnLessThenDeadline = validateDurations(newData.deadlinePeriod, newData.warningPeriod);
                        newData.id = uuidv4();

                        if (!newData.name) {
                          setValidation({
                            validationError: 'name',
                            validationText: t('nameEmptyError'),
                            validationErrorCheck: true,
                          });

                          reject();
                          return;
                        } else if (!isDeadlineValidated) {
                          setValidation({
                            validationError: 'deadlinePeriod',
                            validationText: t('deadlinePeriodFormatError'),
                            validationErrorCheck: true,
                          });

                          reject();
                          return;
                        } else if (!isWarningValidated) {
                          setValidation({
                            validationError: 'warningPeriod',
                            validationText: t('warningPeriodFormatError'),
                            validationErrorCheck: true,
                          });

                          reject();
                          return;
                        } else if (!isWarnLessThenDeadline) {
                          setValidation({
                            validationError: 'warningPeriod',
                            validationText: t('warningLessThenDeadlineError'),
                            validationErrorCheck: true,
                          });

                          reject();
                          return;
                        } else {
                          setPriority({ ...priority, values: [...priority.values, newData] });

                          setValidation({
                            validationError: '',
                            validationText: '',
                            validationErrorCheck: false,
                          });

                          resolve();
                        }
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise<void>((resolve, reject) => {
                        let isDeadlineValidated = validateDuration(newData.deadlinePeriod);
                        let isWarningValidated = validateDuration(newData.warningPeriod);
                        let isWarnLessThenDeadline = validateDurations(newData.deadlinePeriod, newData.warningPeriod);

                        if (!newData.name) {
                          dispatch(openSnackbar({ message: t('nameEmptyError'), severity: 'error', display: true }));

                          reject();
                          return;
                        } else if (!isDeadlineValidated) {
                          dispatch(openSnackbar({ message: t('deadlinePeriodFormatError'), severity: 'error', display: true }));

                          reject();
                          return;
                        } else if (!isWarningValidated) {
                          dispatch(openSnackbar({ message: t('warningPeriodFormatError'), severity: 'error', display: true }));

                          reject();
                          return;
                        } else if (!isWarnLessThenDeadline) {
                          dispatch(openSnackbar({ message: t('warningLessThenDeadlineError'), severity: 'error', display: true }));

                          reject();
                          return;
                        } else {
                          const dataUpdate = [...priority.values];
                          const index = dataUpdate.findIndex((x) => x.id === oldData?.id);
                          dataUpdate[index] = newData;
                          setPriority({ ...priority, values: [...dataUpdate] });

                          setValidation({
                            validationError: '',
                            validationText: '',
                            validationErrorCheck: false,
                          });

                          resolve();
                        }
                      }),
                    onRowDelete: (oldData) =>
                      new Promise<void>((resolve) => {
                        const dataDelete = [...priority.values];
                        const index = dataDelete.findIndex((item) => item.id === oldData.id);
                        if (index > -1) {
                          dataDelete.splice(index, 1);
                          setPriority({ ...priority, values: [...dataDelete] });
                        }
                        resolve();
                      }),
                  }}
                />
              </Grid>
            </Paper>
          </Grid>
        )}

        <Grid container item xs={12} sm={12} md={12} lg={12}>
          <Paper className="p-5 w-full space-y-4">
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Typography gutterBottom variant="h6">
                {t('teamConfigurationClinicalSystemsHeader')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12}>
              {isGettingTeamConfiguration ? (
                <Fade in={isGettingTeamConfiguration} style={{ transitionDelay: isGettingTeamConfiguration ? '800ms' : '0ms' }} unmountOnExit>
                  <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />
                </Fade>
              ) : (
                <React.Fragment>
                  <div>
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="configuration-clinical-type">{t('type')}</InputLabel>
                      <Select id="configuration-clinical-type" label={t('type')} value={clinical.type} onChange={handleClinicalType}>
                        <MenuItem value={'None'}>{t('none')}</MenuItem>
                        <MenuItem value={'Emis'}>{t('emis')}</MenuItem>
                        <MenuItem value={'SystmOne'}>{t('systmOne')}</MenuItem>
                        <MenuItem value={'Vision'}>{t('vision')}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className="pt-6">
                    <TextField
                      fullWidth={true}
                      label={t('teamConfigurationClinicalSystemsIP')}
                      disabled={clinical.type != 'Emis'}
                      variant="outlined"
                      value={clinicalIPFix}
                      onChange={handleClinicalIPChange}
                    />
                  </div>

                  <div className="pt-6">
                    <TextField
                      fullWidth={true}
                      label={t('teamConfigurationClinicalSystemsNumber')}
                      disabled={clinical.type != 'Emis'}
                      variant="outlined"
                      value={clinicalCustomerNumberFix}
                      onChange={handleClinicalCustomerNumberChange}
                    />
                  </div>

                  <div className="pt-6">
                    {clinical.type === 'Emis' ? (
                      <FormControlLabel
                        control={<Checkbox color="default" checked={isTestSystem} onChange={handleIsTestSystemChange} />}
                        label={t('isTestSystemCheckboxLabel')}
                      />
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid container item xs={12} sm={12} md={12} lg={12} className="justify-end pt-5">
          <Button className="!mr-5" variant="contained" color="secondary" onClick={onReset} disabled={isUpdatingConfiguration}>
            {isUpdatingConfiguration && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('cancelButtonText')}
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={isUpdatingConfiguration}>
            {isUpdatingConfiguration && <CircularProgress size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
            {t('submitButtonText')}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TeamConfiguration;
